import React, {Component} from 'react';
import { timeline } from '../../utils/timeline';
import anime from 'animejs';
import Inview from '../Inview';

class TextBlock extends Component {

    constructor(props) {
        super(props);
        
        this.ref = React.createRef();
    }

    activate = () => {
        this.loop();

        const tl = timeline({})
        .add({
            targets: this.ref.current,
            opacity: [0, 1],
            // filter: {
            //     value: ['blur(10px)', 'blur(0px)'],
            //     duration: 1000,
            // },
            // translateZ: 0,
            easing: 'linear',
            duration: 2400,
            delay: 500
        })
    };


    loop = () => {
        const tl = timeline({
            config: {
                loop: true,
            },
            onComplete: () => {
                //console.log('complete');
            }
            // direction: 'alternate',
        })
        .add({
            targets: this.ref.current.querySelectorAll('span'),
            // opacity: [1, .9],
            filter: [
                { value: 'blur(0px)', duration: 1000 },
                { value: 'blur(1px)', duration: 2000 },
                { value: 'blur(0px)', duration: 2000 },
            ],
            translateZ: 0,
            // filter: [
            //     { value: 'blur(0px)', duration: 1000},
            //     { value: 'blur(1px)', duration: 1000},
            //     { value: 'blur(0px)', duration: 1000, delay: 2000 },
            // ],
            easing: 'easeOutQuad',
            delay: function(el, i, l) {
                // console.log(anime.random(0, 1000));
                return anime.random(0, 4000);
            }
        })
    };

    render() {
        const { text, size, pos, title } = this.props.data;

        return (
            <Inview
                el={this.ref.current}
                start={-1}
                end={1}
                cb={this.activate}
            >
                <div className={`text-block sides text-block--${pos}`} ref={this.ref}>
                    <div className={`text-block__wrap`}>
                        <div className={`text-block__content text-${size}`}>
                            { title ? 
                                <p className={`text-block__title`} dangerouslySetInnerHTML={{__html: title }}/>
                            : '' }
                            <div className={`text-block__text`} dangerouslySetInnerHTML={{__html: text }}/>
                        </div>
                    </div>
                </div>
            </Inview>
        );
    }
}

export default TextBlock;
                