import { throttle } from "lodash";
import { isMobile } from "./../utils/is-mobile";
import { af as AF } from '@gladeye/af';

class Mouse3D {
    _instance;

    static instance(){
        if(this._instance) return this._instance;
        return this._instance = new this();
    }

    mouse = {
        x: 0, 
        y: 0
    };

    smoothMouse = {
        x: 0, 
        y: 0
    };

    constructor(){
        if (!isMobile) {
            this.af = AF();
            window.addEventListener('mousemove', this.handleMove);
            this.af.addRead(this.read);
        }
    }

    handleMove = (e) => {
        const { clientX: x, clientY: y } = e;
        const { innerWidth: w, innerHeight: h } = window;

        this.mouse.x = x / w * 2 - 1;
        this.mouse.y = y / h * 2 - 1;
    };

    read = () => {
        const { smoothMouse } = this;
        smoothMouse.x += ( this.mouse.x - smoothMouse.x ) * 0.03;
        smoothMouse.y += ( this.mouse.y - smoothMouse.y ) * 0.03;
        // console.log(smoothMouse);
    };
}

export default Mouse3D;