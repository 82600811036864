import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { AppContext } from "../context/app-context";

class TransitionRouter extends Component {
    static contextType = AppContext;

    componentDidUpdate(prevProps) {
        const { location, onLocationChange } = this.props;

        if (prevProps.location !== location) {
            if (onLocationChange) {
                onLocationChange(
                    this.getFirstSegment(prevProps.location.pathname),
                    this.getFirstSegment(location.pathname)
                );
            }
        }
    }

    componentDidMount() {
        const { location, onLocationChange } = this.props;
        if(onLocationChange) {
            onLocationChange(null, this.getFirstSegment(location.pathname));
        }
    }

    getFirstSegment = (pathname, defaultSegment = "home") => {
        return pathname && pathname !== "/"
            ? pathname.split("/")[1]
            : defaultSegment;
    };


    render() {
        const { onLocationChange, children, location, className, ...rest } = this.props;
        const { transitionDuration } = this.context;

        return (
            <TransitionGroup className={className}>
                <CSSTransition className={'test'} key={location.pathname} timeout={transitionDuration} {...rest}>
                    {state => children(state)}
                </CSSTransition>
            </TransitionGroup>
        );
    }
}

export default TransitionRouter;
