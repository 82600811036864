import React, {Component} from 'react';
import { timeline } from '../../utils/timeline';
import Inview from '../Inview';
import Parallax from '../Parallax';
import anime from 'animejs';
import { isMobile } from "./../../utils/is-mobile";
import { clamp, round, clean } from '../../utils';

// Types:
// 1 - left aligned caption -> right
// 2 - right ligned, tall
// 3 - full

class Image extends Component {
    constructor(props) {
        super(props);
        
        this.ref = React.createRef();
        this.imageRef = React.createRef();
        this.imageContent = React.createRef();

        this.state = {
            active: false
        };
    }
    
    componentDidMount() {
        const { type } = this.props.data;

        if (!isMobile) {

            if (type === 'full') {
                this.imageTimeline = anime.timeline({
                    autoplay: false
                })
                .add({
                    targets: this.imageRef.current,
                    // scale: {
                    //     // value: [1.2, 1], 
                    //     easing: 'easeOutQuad'
                    // },
                    translateY: [-200, 200],
                    translateZ: 0,
                    easing: 'linear',
                    duration: 2000,
                });
            } else {
                // this.imageTimeline = anime.timeline({
                //     autoplay: false
                // })
                // .add({
                //     targets: this.imageContent.current,
                //     translateY: [-50, 50],
                //     translateZ: 0,
                //     easing: 'linear',
                //     duration: 2000,
                // });
            }
        }
    }

    activate = () => {
        const { type } = this.props.data;

        if (type !== 'full') {
            this.loop();
            const tl = timeline({})
            .add({
                targets: this.ref.current,
                opacity: [0, 1],
                // translateY: [300, 0],
                translateZ: 0,
                easing: 'easeInOutSine',
                duration: 3400
            })
            .add({
                targets: this.imageRef.current,
                scale: [1.1, 1],
                translateZ: 0,
                easing: 'easeOutCubic',
                duration: 3400
            }, 0)
        } else {
            this.setState({ active: true });
        }
    };

    loop = () => {
        const tl = timeline({
            config: {
                loop: true,
            }
            // direction: 'alternate',
        })
        .add({
            targets: this.ref.current.querySelectorAll('span'),
            filter: [
                { value: 'blur(0px)', duration: 1000 },
                { value: 'blur(2px)', duration: 2000 },
                { value: 'blur(0px)', duration: 2000 },
            ],
            translateZ: 0,
            easing: 'linear',
            delay: function(el, i, l) {
                return anime.random(0, 3000);
            }
        })
    };

    write = (smoothVal) => {
        if (!isMobile) {
            const progress = clean(smoothVal, -1, 1);
            this.imageTimeline.seek(this.imageTimeline.duration * progress);
        }
    };

    render() {
        const { image, caption, type } = this.props.data;
        const { active } = this.state;
        
        const TagName = type === 'full' ? Parallax : 'div';
        const props = {
            className: "image__image"
        };

        if (type === 'full') {
            props.start = -1;
            props.end = 1;
            props.cb = this.write;
        }

        const TagName2 = type !== 'full' ? Parallax : 'div';
        const props2 = {
            className: "image__wrap"
        };

        if (type !== 'full') {
            props2.start = -1;
            props2.end = 1;
            props2.cb = () => {};//this.write;
        }

        return (
            <Inview
                el={this.ref.current}
                start={-1}
                end={1}
                cb={this.activate}
            >
                <div className={`image image--${type} ${active ? 'is-active' : ''}`} ref={this.ref}>
                    <TagName2 {...props2}>
                        <div className="image__content" ref={this.imageContent}>
                            
                            <TagName {...props}>
                                <img ref={this.imageRef} src={image} alt="" />
                            </TagName>

                            { caption ? (
                                <div className={`image__caption text-14`} dangerouslySetInnerHTML={{__html: caption }}/>
                            ) : '' }
                        </div>
                    </TagName2>
                </div>
            </Inview>
        );
    }
}

export default Image;
                