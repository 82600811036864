import React, { Component } from 'react';
import Back from "./Back";
import Hero from "./case-study/Hero";
import Credits from "./Credits";
import Blocks from "./case-study/Blocks";
import ScrollDown from "./ScrollDown";

const blocks = [
    {
        block: 'TextBlock',
        data: {
            size: 23,
            pos: 'right',
            text: `
                <p>More than <strong>a billion</strong> people lack one of the most important human rights: the ability to prove who they are. Without identification, these people can’t access basic services like healthcare, education, banking, or voting.</p>
            `
        }
    },
    {
        block: 'FocusText',
        data: {
            maxWidth: '840px',
            text: `Without identification, people are effectively invisible to the world. They are at risk of falling through the cracks in society and into poverty, exploitation and suffering.`
        }
    },
    {
        block: 'Image',
        data: {
            type: 'left',
            caption: `Gevgelija, Macedonia - December 23, 2015: Refugees arriving at the refugee camp of Vinojug in Gevgelija (Macedonia) after having crossed the border with Greece at Eidomeni.`,
            image: require(`../../images/about/walk.jpg`)
        }
    },
    {
        block: 'TextBlock',
        data: {
            size: 18,
            pos: 'right',
            text: `
                <p>Undocumented people make easy prey for human traffickers and are more likely to be coerced into modern slavery.</p>
                <p>But it is not only those without IDs who are vulnerable. People living in modern societies are at risk of losing control of their identities as they become increasingly monitored and tracked by private companies and authoritarian regimes.</p>
            `
        }
    },
    {
        block: 'FocusText',
        data: {
            maxWidth: '840px',
            text: `Our mission is to ensure that everyone can prove who they are while maintaining control over their identity personal data.`
        }
    },
    {
        block: 'TextBlock',
        data: {
            size: 18,
            pos: 'left',
            text: `
                <p>Until recently, a future where everyone on the planet had access to a personal identity was an unattainable goal. But now, with more awareness, better technology, and international will, there is hope.</p> 
                <p>But we need to act quickly as the need for people to prove who they are is becoming even more critical: conflicts, wars, climate change and resource scarcity are forcing people to put themselves in unsafe situations, leaving them more vulnerable than ever.</p>
            `
        }
    },
    {
        block: 'Title',
        data: {
            size: 18,
            text: `Thankfully, a combination of awareness, international will, and technology is finally offering hope.`,
        }
    },
    {
        block: 'Video',
        data: {
            image: require(`../../images/about/video-holder.jpg`),
            id: `wylCxMUH6VE`,
        }
    },
    {
        block: 'TextBlock',
        data: {
            size: 18,
            pos: 'right',
            text: `
                <p>We launched the <i>Shadows in the Dark</i> documentary series to shed light on the challenges that people face when they don’t have a recognised form of identity.</p>
                <p><i>Shadows in the Dark</i> will go beyond the cold statistics, telling real-life stories of struggle, but also explore the thrilling potential for meaningful change, and how we can make it happen.</p>
            `
        }
    },
    {
        block: 'Image',
        data: {
            type: 'left',
            caption: `Speaking at TEDx, World Identity Network co-founder and CEO Dr. Mariana Dahan offers a glimpse of the immense problems linked to lack of personal identity, from the “invisible” children phenomena, to human trafficking and modern slavery.`,
            image: require(`../../images/about/ted-talk.jpg`)
        }
    },

    {
        block: 'TextBlock',
        data: {
            size: 18,
            pos: 'right',
            text: `
                <p>We have the opportunity to change the lives of people all around the world. This is the perfect time to recognize everyone on earth as equals through a worldwide identification system. Everyone should be able to prove who they are and have control over their identity, anytime, anywhere.</p>
                <p>This is the first step in writing the next chapter of human history, the global chapter. </p>
            `
        }
    },
];

class About extends Component {

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
    }

    render() {
        const { state } = this.props;

        const hero = { 
            title: `<i>Shadows in <br/>the Dark</i><small>Documentary</small>`, 
            sub: 'About',
            special: true
        };

        return (
            <div className="about">
                <Back />
                <Hero hero={hero} special={true} state={state} />
                <ScrollDown state={state}/>
                <Blocks blocks={blocks}/>
                <Credits/>
            </div>
        );
    }
}

export default About;
                