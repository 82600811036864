export const refugee = {
    title: 'No Place<br/>to&nbsp;Call Their<br/>Home',
    sub: 'Refugee Crisis',
    image: require(`../../images/refugee/hero-2.jpg`),
    slide: {
        image: require(`../../images/refugee/slide.jpg`),
        mask: require(`../../images/refugee/slide-mask.jpg`),
        w: 600,
        h: 998
    },
    blocks: [
        {
            block: 'TextBlock',
            data: {
                size: 23,
                pos: 'right',
                text: `
                    <p>Thousands of people migrate each year, some by choice, but many by force.</p> 
                    <p>Migration is becoming an overwhelming problem as many countries do not have the foundations to support the increasing number of <span>people fleeing</span> their homes.</p>
                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'left',
                caption: `Since 4 April 2014 more than 130,000 nationals of the Democratic Republic of the Congo (DRC) have been expelled from the Republic of Congo (Brazzaville). (UN Photo/Sylvain Liechti)`,
                image: require(`../../images/refugee/image-1.jpg`)
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'right',
                text: `
                    <p>Today there are approximately <span>65.6 million</span> refugees and internally displaced people worldwide. This number is increasing rapidly and by 2050 it is estimated that 10 percent of the world’s population will have moved from their place of birth, either by will or by force.</p>
                    <p>Many of these people are moving for their own <span>safety</span>. They are moving to get away from war, violent conflicts and social injustices, or to escape natural disasters.</p> 
                    <p>They leave in the hope they can make a better life for them and their families elsewhere. But as more families <span>flee</span>, there is becoming an extraordinarily high presence of children in migration.</p>
                `
            }
        },
        {
            block: 'FocusText',
            data: {
                text: `Today children make up over half of the world’s refugees.`
            }
        },
        {
            block: 'Image',
            data: {
                type: 'right',
                caption: `A Red Cross worker looks towards a minor after disembarking from the Italian Coast Guard vessel 'Diciotti' following a rescue operation of migrants and refugees at sea on June 13, 2018 in the port of Catania, Sicily. (Photo by GIOVANNI ISOLINO / AFP via Getty Images)`,
                image: require(`../../images/refugee/image-2.jpg`)
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'left',
                text: `
                    <p>In the process of migration, these children typically lose <span>opportunities</span> of economic, social and personal development on top of the other barriers migrants face.</p>
                    <p>Most migrants suffer from hunger, sleep deprivation, anxiety and desperation. This exhausting journey to a new home leaves refugees weak and more <span>vulnerable</span> to exploitation.</p>
                    <p>Once in their new country, they then battle prejudice and discrimination, as well as having poor access to housing, healthcare and education. Lack of recognized proof of their <span>identity</span> makes it even more difficult for them to gain residency in safer countries, adjust to living in a new place, and get access to basic services.</p>
                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'full',
                image: require(`../../images/refugee/banner-image.jpg`)
            }
        },
        {
            block: 'Title',
            data: {
                text: `Tey's Story`,
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'column',
                text: `
                    <p>Tey was born in Kuwait in 1990, at the height of the Gulf War, before moving to his homeland of Syria. During the war, the country's civil registries, along with Tey’s <span>birth certificate</span> and other ID documents, were destroyed.</p> 
                    <p>As an adult, Tey aspired for a better life than what he believed he would get in Syria. He decided to move to the Netherlands after receiving a job offer from a company there.</p>
                    <p>While living in the Netherlands, Tey became attached to his adopted country, getting himself an apartment, friends, and a dog. So when his contract <span>expired</span> 5 years later, Tey knew he wanted to continue his life there, rather than move back to Syria.</p>
                    <p>His only chance of staying in the Netherlands was to apply for asylum, but the destruction of his <span>identification</span> records back in his childhood meant that he had no way to prove who he was.</p>
                    <p>Tey spent nearly 2 years in a refugee camp trying to verify his identity. With the help of some refugees with software developing skills, he created a mechanism to verify the identities of everyone in the camp. He’s now supporting the Self-Sovereign Identity movement, so there’s a chance that no one is considered <span>‘invisible’</span> again.</p>
                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'right',
                image: require(`../../images/refugee/image-tey.jpg`)
            }
        },
        {
            block: 'FocusText',
            data: {
                text: `One day he woke up in a refugee camp and couldn’t prove who he was and where he belongs.`
            }
        },

        {
            block: 'Image',
            data: {
                type: 'left',
                caption: `Children at Maternal and Child Welfare Center (MCWC) Palash Community Clinic, in Narsingdi (outside Dhaka). (Photo by Rama George-Alleyne / World Bank)`,
                image: require(`../../images/refugee/image-wai.jpg`)
            }
        },
        {
            block: 'Title',
            data: {
                text: `Wai Wai’s Story`,
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'column',
                text: `
                    <p>At 18 years old Wai Wai and her family were arrested.</p> 
                    <p>She was sentenced to <span>17 years</span> in one of Burma’s most notorious prisons because her father had been advocating for better rights for people of the Rohingya ethnicity.</p>
                    <p>The stateless people of Rohingya, a Muslim minority group, are often the target of persecution by government officials and have a history of being systematically brutalised.</p>
                    <p>For Wai Wai, her imprisonment came as a shock. “I was in prison because of my <span>identity</span>!”</p> 
                    <p>During her time in Insein Prison, the cells were cramped and overcrowded, the medical care was poor, and there was a lack of food.</p>
                    <p>She described it as “full of horrors”. She had no hope, and no future.</p>
                    <p>Wai Wai was freed from prison when the country transitioned into democracy, but she found that most Rohingya people lived in <span>fear</span>: considered a “stateless group”, they were denied Burmese citizenship.</p>
                    <p>For the Rohingya people, having an officially recognized, legal ID would be life changing, as it would give them access to basic human rights, services and protection.</p>
                    <p>But the types of systems which are currently being used don’t have the necessary privacy measures in place. Privacy-enhancing features are important to make sure that Rohingya’s personal <span>identity</span> data won’t be revealed to third parties.</p>
                `
            }
        },
    ]
}