export const trafficking = {
    title: 'Lost and<br/>Invisible<br/>Children',
    sub: 'Human Trafficking',
    image: require(`../../images/trafficking/hero-2.jpg`),
    slide: {
        image: require(`../../images/trafficking/slide.jpg`),
        mask: require(`../../images/trafficking/slide-mask.jpg`),
        w: 600,
        h: 847
    },
    blocks: [
        {
            block: 'TextBlock',
            data: {
                size: 23,
                pos: 'right',
                text: `
                    <p>Undocumented children are easy prey for human traffickers. Without a personal ID, they are <span>invisible</span>. And because they can’t be counted or accounted for, human traffickers are more likely to get away with exploiting them, transporting them across borders, and profiting from their suffering.</p> 
                    <p>Traffickers target vulnerable groups such as migrants and children and strip them of any <span>identification</span>. These victims are then smuggled across borders using fake ID documents before being sold to brothels, forced into modern slavery, or used in the organ trade.</p>
                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'left',
                caption: `Dhaka, Bangladesh - March 26, 2016. A child laborer working inside a workshop at the Dock Yard in Dhaka, Bangladesh.`,
                image: require(`../../images/trafficking/image-1.jpg`)
            }
        },
        {
            block: 'FocusText',
            data: {
                text: `Today, 600 million children and minors cannot prove who they&nbsp;are.`
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'column',
                text: `
                    <p>These vulnerable children live in the most isolated regions of the world’s poorest countries. Many of them are <span>left behind</span> by migration or are on the move themselves. Others are involuntarily trapped in orphanage systems.</p>

                    <p>We like to believe that orphanages exist to protect and nurture children <span>without families</span>. But many operate as businesses which regard their trafficked children as a product to be exploited.</p>

                    <p>With the growing phenomenon of voluntourism, illegal orphanage practices are getting more and more popular. Well-meaning western tourists are trying to help these orphanages by staying at them, helping run them and making contributions to them, as a form of volunteering.</p>

                    <p>But most orphanages are exploiting this. There is now a strong demand for young children to be trafficked to orphanages to boost their tourism appeal. Now children are <span>being taken</span> from their homes and parents and are brought to orphanages for rewards.</p>

                    <p>Most of the time they are taken by people that they know, often it’s by their own family members.</p>
                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'full',
                image: require(`../../images/trafficking/banner-image.jpg`)
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'column',
                text: `
                    <p>As revealed in recent investigative reports, orphanage owners falsify stolen children's identification documents and place notices in the local newspapers with their new <span>identities</span>. If no one comes forward to claim the child, the government certifies them as an orphan and they begin their lives in exploitation.</p>

                    <p>It doesn’t take long for a child with a forged <span>identity</span>, or with no identity at all, to become lost in the vast system.</p>

                    <p>The effects of this practice are devastating for parents who try to reclaim their ‘orphaned’ children because if they have no identity, they can’t prove the child is theirs. And if a victim is ever lucky enough to <span>escape</span>, the fake identities can prevent them from ever reclaiming their lives.</p>

                    <p>But now the international community is beginning to recognise that this is a form of child trafficking. A resilient, non-alterable identity could help to address some of these problems and make it harder for recruiters to produce <span>nameless</span> children.</p>
                `
            }
        },
        {
            block: 'FocusText',
            data: {
                text: `Of the 8 million children estimated to be living in orphanages, 80 percent are not orphans.`
            }
        },
        {
            block: 'Title',
            data: {
                text: `Zafina’s Story`,
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'column',
                text: `
                    <p>When she was <span>a child</span>, Zafina Yonjan’s parents paid a large amount of money for a recruiter to take her to Kathmandu to receive a better education.</p> 
                    <p>But once she was in the recruiter’s control, he took her to an orphanage in return for even more money.</p>
                    <p>During her time in the orphanage, Zafina was taken advantage of and <span>abused</span>.</p>
                    <p>But years later, after transferring to a better institution, she had her real <span>identity</span> confirmed. Zafina was able to escape the orphanage and find her family.</p> 
                    <p>The <i>Shadows in the Dark</i> Documentary series will explore how we can help trapped and exploited children, like Zafina, to <span>escape</span> these illegal orphanages and reclaim their lives.</p>
                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'left',
                caption: `Beira, Mozambique - August 26, 2018. Orphanage St. Egidius: Portrait of a girl in an orphanage for AIDS patients on August 26, 2018 in Beira, Mozambique. (Photo by Ute Grabowsky/Photothek via Getty Images)`,
                image: require(`../../images/trafficking/image-2.jpg`)
            }
        },
        {
            block: 'Title',
            data: {
                text: `Cherry’s Story`,
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'column',
                text: `
                    <p>Child trafficking doesn’t only happen in remote areas or in emerging economies. It also occurs in world’s richest counties, in plain sight.</p> 
                    <p>At 10, Cherry and her sister fled abuse from their stepfather. For three days, they walked along railway lines to Cochrane, Canada where they were apprehended, <span>separated</span>, and put into foster care. Over the next eight years, Cherry lived in twenty different care and foster homes. They’ve lost track of her <span>identification</span> papers, of her grounding ties with her sister and other family members.</p>
                    <p>Alone and lost, Cherry was only 14 years old when she followed a smuggler to a different state in Canada, where she was <span>forced</span> into prostitution. For the next eight years, Cherry worked in the sex trade in various states in North America; and she also became addicted to heroin and cocaine. One day, Cherry managed to run away from her pimp to Los Angeles. But when police found her suffering from a drug overdose, she was returned to her smugglers in Vancouver: because that’s what her fake <span>ID papers</span> stated she was from.</p>
                    <p>Back to her perpetrators, Cherry was raped and nearly murdered. Cherry returned to sex trade. But when she unexpectedly became pregnant, Cherry decided to stand up against the violence and injustice. She resolved to turn her life around by leaving prostitution and drugs behind her. With help from a local NGO, Cherry became a vocal activist fighting for disadvantaged youth and raising awareness about the systematic, unrebuked abuse of children.
                    </p>
                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'right',
                image: require(`../../images/trafficking/image-сherry.jpg`),
            }
        },
    ]
}