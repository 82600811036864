import React, {Component} from 'react';
import { af as AF } from '@gladeye/af';
import { timeline } from '../utils/timeline';
import wordWrap from '../utils/word-wrap';
import anime from 'animejs';

class Intro extends Component {
    timeoutId;
    timelines = [];
    items = [
        { text: 'Over <strong>1 billion</strong> people lack one of the most important basic&nbsp;rights.', duration: 1000 },
        { text: `They cannot prove who&nbsp;they&nbsp;are.`, duration: 600 },
        { text: `Without identification, basic services like healthcare and education are out of reach.`, duration: 1200 },
        { text: `Without identification, these  people are invisible.`, duration: 800 },
    ];

    constructor(props) {
        super(props);
        
        this.items.forEach((item) => {
            item.ref = React.createRef();
        });
        
        this.lineRef = React.createRef();
        this.skipRef = React.createRef();

        this.af = AF();
    }

    componentDidMount() {
        window.addEventListener('load', this.handleLoad);
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad);
        clearTimeout(this.timeoutId);

        this.timelines.forEach((tl) => {
            tl.destroy();
        });
    }
    
    handleLoad = (e) => {
        this.timeoutId = setTimeout(() => {
            this.setState({
                current: 0,
                started: true
            });
            this.showSkip();
            this.loop(0);
        }, 1000);
    };

    loop = (index) => {
        const { current } = this.state;
        const { finishIntro } = this.props;
        
        const next = () => {
            const nextIndex = current + 1;

            if (nextIndex < this.items.length) {
                this.setState({
                    current: nextIndex
                });

                this.loop(nextIndex);

                if (nextIndex === this.items.length - 1) {
                    // console.log('hideskip', nextIndex, this.items.length - 1);
                    this.hideSkip();
                }
            } else {
                finishIntro();
            }
        }

        this.animateItem(index, { onComplete: next });
    };

    animateItem = (index, config) => {
        const tl = timeline(config).
        add({
            targets: this.items[index].ref.current.querySelectorAll('span'),
            opacity: [0, 1],
            filter: {
                value: ['blur(10px)', 'blur(0px)'],
                duration: 1000,
            },
            translateZ: 0,
            easing: 'linear',
            duration: 1400,
            delay: anime.stagger(90)
        }).
        add({
            targets: this.items[index].ref.current.querySelectorAll('span'),
            opacity: [1, 0],
            filter: {
                value: ['blur(0px)', 'blur(10px)'],
                duration: 1000,
            },
            translateZ: 0,
            easing: 'linear',
            duration: 1000,
            delay: anime.stagger(60),
        }, `+=${this.items[index].duration}`);

        this.timelines.push(tl);
    };

    showSkip = (config = {}) => {
        const tl = timeline(config).
        add({
            targets: this.lineRef.current,
            translateY: ['100%', '0%'],
            translateZ: 0,
            easing: 'easeOutQuart',
            duration: 1000,
        }).
        add({
            targets: this.skipRef.current,
            translateY: ['110%', '0%'],
            translateZ: 0,
            easing: 'easeOutQuart',
            duration: 1000,
        }, 200);
        this.timelines.push(tl);
    };

    hideSkip = (config = {}) => {
        const tl = timeline(config).
        add({
            targets: this.lineRef.current,
            translateY: ['0%', '100%'],
            translateZ: 0,
            easing: 'easeOutQuart',
            duration: 1000,
        }).
        add({
            targets: this.skipRef.current,
            translateY: ['0%', '110%'],
            translateZ: 0,
            easing: 'easeOutQuart',
            duration: 1000,
        }, 200);
        this.timelines.push(tl);
    };

    renderItem = (item, index) => {
        const config = {
            text: item.text,
            length: 2,
            indent: `<span>`,
            newline: '</span>',
        };
        
        return (
            <div 
                className={`intro__item intro__item--${index + 1}`}
                key={index}
            >
                <h4
                    ref={this.items[index].ref}
                    dangerouslySetInnerHTML={{__html: wordWrap(config) }}/>
            </div>
        );
    };

    render() {
        const { finishIntro } = this.props;

        return (
            <div className={`intro sides`}>

                <div className="intro__list">
                    { this.items.map(this.renderItem) }
                </div>

                <div className="intro__skip">
                    <div className="intro__skip-line">
                        <span ref={this.lineRef}></span>
                    </div>
                    <div 
                        className="intro__skip-name"
                        onClick={finishIntro}
                    >
                        <span ref={this.skipRef}>Skip introduction</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Intro;
                