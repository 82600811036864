import React, {Component} from 'react';
import Hero from "./Hero";
import Blocks from "./Blocks";
import Back from "../Back";
import Next from "./Next";
import ScrollDown from "../ScrollDown";
import { data } from "../../data";

class Case extends Component {
    
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        const { slug } = this.props;

        const known = data.findIndex( item => item.slug === slug);
        
        if (known >= 0 && !data[known].noactive) {
            this.setState({
                index: known,
                data: data[known].data
            });
        }
    }

    render() {
        if (this.state.data) {
            const { enableSpecial, state, specialTransition } = this.props;
            const { title, sub, image, blocks } = this.state.data;
            const hero = { title, sub, image };

            let nextIndex = (this.state.index + 1) % data.length;

            while (data[nextIndex].noactive) {
                nextIndex = (nextIndex + 1) % data.length
            }

            const next = data[nextIndex];
            

            return (
                <div className="case">
                    <Back />
                    <Hero 
                        hero={hero}
                        state={state}
                    />
                    <ScrollDown state={state} parent={'case'} />
                    <Blocks blocks={blocks}/>
                    <Next 
                        next={next} 
                        enableSpecial={enableSpecial}
                        state={state}
                        specialTransition={specialTransition}
                    />
                </div>
            );
        } else {
            return '';
        }
    }
}

export default Case;
                