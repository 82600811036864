import React, {Component} from 'react';
import { af as AF } from '@gladeye/af';
import classNames from "classnames";
import { isMobile } from "./../utils/is-mobile";
import { AppContext } from "../context/app-context";

class ScrollDown extends Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.af = AF();
        this.state = {
            hidden: true
        };
    }

    componentDidMount() {
        this.af.addRead(this.read);
    }

    componentWillUnmount() {
        this.af.removeRead(this.read);
    }
    
    componentDidUpdate(prevProps, prevState) {
        // const { menuActive } = this.context;
    }

    read = () => {
        const { current, previous } = this.context.location;
        const { menuActive } = this.context;
        const { pageYOffset, innerHeight: h } = window;

        if (menuActive) {
            this.hide();
        } else {
            if (pageYOffset > h/3) {
                this.hide();
            } else {
                if (!isMobile) {
                    this.show();
                } else {
                    if (current === 'home') {
                        this.show();
                    } else {
                        this.hide();
                    }
                }
            }
        }
    };

    show = () => {
        if(!this.state.hidden) return;
        this.setState({
            hidden: false
        });
    };

    hide = () => {
        if(this.state.hidden) return;
        this.setState({
            hidden: true
        });
    };

    render() {
        const { hidden } = this.state;
        const { state, parent } = this.props;

        let isHidden = hidden;

        if (state === 'exiting' || state === 'entering') {
            isHidden = true;
        }
        
        return (
            <div className={classNames({
                    'scroll-down': true,
                    'scroll-down--case': parent === 'case',
                    'is-scroll-down-hidden': isHidden
                })}
            >
                <div className="scroll-down__wrap">
                    <div className="scroll-down__text text-13">
                        <span>{ isMobile ? 'Swipe' : 'Scroll' }</span>
                    </div>

                    <div className="scroll-down__line-wrap">
                        <div className="scroll-down__line">
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ScrollDown;