import React, {Component} from 'react';
import { Router, Location } from "@reach/router";
import Home from "./components/Home";
import About from "./components/About";
import Case from "./components/case-study/Case";
import Smoke from "./components/Smoke";
// import Menu from "./components/Menu";
// import Cursor from "./components/Cursor";
import HeadingPos from "./components/HeadingPos";
import TransitionRouter from "./components/TransitionRouter";
import { data } from "./data";
import { af as AF } from '@gladeye/af';
import { isIE } from "./utils/detectIE";
import { AppContext } from "./context/app-context";
import { isMobile } from "./utils/is-mobile";

const CLASS_NOSCROLLBARS = 'is-no-scrollbar'; // Hide scrollbar

export default class App extends Component {
    scrollY = 0;
    clickedSpecialTransition = false;
    
    timeoutId = null;

    constructor(props) {
        super(props);

        this.af = AF();

        this.state = {
            currentSlide: 0,
            currentData: 0,
            prevData: null,
            introActive: true,
            transitionActive: false, // Page transition active
            smokeDisabled: true,
            appContext: {
                transitionDuration: 1500,
                transitionSpecial: false, // Custom transition
                menuActive: false,
                location: {
                    current: '',
                    previous: ''
                },
                headingPos: null
            },
        };
    }

    componentDidMount() {
        this.af.addRead(this.recordScroll);

        if (isIE) {
            document.body.classList.add('is-ie');
        } else {
            document.body.classList.add('is-not-ie');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { current, previous } = this.state.appContext.location;

        if (previous === null) {
            if (current === 'home') {
                this.disableScrollbar();

                if (!this.state.introActive) {
                    this.enableSmoke();
                }
            } else {
                this.finishIntro();
            }
        }
    }

    activateMenu = () => {
        const { menuActive } = this.state.appContext;
        if (menuActive) return;

        this.setState(({ appContext }) => {
            appContext.menuActive = true;
            return { appContext };
        });
    };

    deactivateMenu = () => { 
        const { menuActive } = this.state.appContext;
        if (!menuActive) return;

        this.setState(({ appContext }) => {
            appContext.menuActive = false;
            return { appContext };
        });
    };

    enableSmoke = () => {
        if (!this.state.smokeDisabled) return;

        this.setState({
            smokeDisabled: false
        })
    };

    disableSmoke = () => { 
        if (this.state.smokeDisabled) return;

        this.setState({
            smokeDisabled: true
        })
    };

    handleLocationChange = (previous, current) => {
        this.setState(({ appContext }) => {
            appContext.location = { current, previous };
            return { appContext };
        });
    };

    finishIntro = () => {
        if (!this.state.introActive) return;
        const { current, previous } = this.state.appContext.location;
        
        this.setState({
            introActive: false
        })
    };

    recordScroll = () => {
        const { pageYOffset } = window;
        
        const record = () => {
            this.scrollY = pageYOffset;
        }

        if (pageYOffset !== this.scrollY) {
            this.af.onNextRead(record);
        }
    };

    goIndex = (index) => {
        const {smokeDisabled} = this.state;
        if (smokeDisabled) return;
        
        // console.log('goIndex', smokeDisabled);

        const currentSlide = index;
        let currentData = index % data.length;
        
        if (currentData < 0) {
            currentData = Math.abs(currentData);
        } else if (currentData > 0) {
            currentData = data.length - currentData;
        }
        
        let prevData = this.state.currentData;

        this.setState({
            currentSlide,
            currentData,
            prevData
        });
    };

    goNext = () => {        
        const { currentSlide: cs} = this.state;
        const currentSlide = (cs - 1);
        this.goIndex(currentSlide);
    };

    goPrev = () => {
        const { currentSlide: cs} = this.state;
        const currentSlide = (cs + 1);
        this.goIndex(currentSlide);
    };

    // Saves `heading-pos` rect
    // Used to find out position of heading to the top & left of the screen
    setHeadingPos = headingPos => {
        this.setState(({ appContext }) => {
            appContext.headingPos = headingPos;
            return { appContext };
        });
    };

    // handleEnter = node => {
    // };

    handleExit = node => {
        this.setState(({ appContext }) => {
            appContext.transitionSpecial = this.clickedSpecialTransition;
            return { appContext, transitionActive: true };
        });

        // if (!isIE) {
        this.disableScroll(node);
        this.disableSmoke();
        // }
    }

    handleEntering = node => {
        const { transitionDuration, location: {current, previous}} = this.state.appContext;
        
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
            if (current === 'home') {
                this.disableScrollbar();
            }
            if (previous === 'home') {
                this.enableScrollbar();
            }
        }, transitionDuration*0.5);
    };

    handleEntered = node => {
        const {current, previous} = this.state.appContext.location;
        this.clickedSpecialTransition = false;
        
        this.enableScroll(node);
        
        if (current === 'home') {
            this.enableSmoke();
        }

        this.setState({
            transitionActive: false
        });
    };

    enableSpecial = () => {
        this.clickedSpecialTransition = true;
    };

    enableScrollbar = () => {
        document.body.classList.remove(CLASS_NOSCROLLBARS);
    };

    disableScrollbar = () => {
        document.body.classList.add(CLASS_NOSCROLLBARS);
    };

    disableScroll = (node) => {
        window.scrollTo(0, 0);
        node.style.position = 'fixed';
        node.style.top = `${-this.scrollY}px`;
    };

    enableScroll = (node) => {
        // if (isIE) {
        node.style.position = '';
        node.style.top = ``;
        window.scrollTo(0, 0);
        // }
    };

    render() {
        const { currentSlide, currentData, changeType, introActive, prevData, appContext } = this.state;
        const { current, previous } = appContext.location;
        const { transitionDuration, transitionSpecial } = appContext;
        
        let transitionType = 'default';
        if (transitionSpecial) {
            if (
                (previous === 'home' && current === 'case') ||
                (previous === 'case' && current === 'case')
            ) {
                transitionType = `${previous}-${current}`;
            }
        }

        return (
            <div className={`app ${isMobile ? 'is-mobile' : ''}`}>
                <AppContext.Provider value={appContext}>
                    {/*<Menu/>*/}
                    {/*<Cursor/>*/}
                    <Smoke 
                        currentSlide={currentSlide} 
                        goNext={this.goNext} 
                        goPrev={this.goPrev} 
                        goNearest={this.goNearest}
                        goIndex={this.goIndex}
                        introActive={introActive}
                        changeType={changeType}
                    />

                    <HeadingPos 
                        setHeadingPos={this.setHeadingPos} 
                    />

                    <Location>
                        {({ location }) => (
                            <TransitionRouter
                                onLocationChange={this.handleLocationChange}
                                location={location}
                                classNames="page"
                                onEnter={this.handleEnter}
                                onEntering={this.handleEntering}
                                onEntered={this.handleEntered}
                                onExit={this.handleExit}
                                className={`transition transition-${transitionType}`}>
                                {state => (
                                    <Router 
                                        location={location} 
                                        primary={false}
                                    >
                                        <About 
                                            path="/about"
                                            state={state}
                                        />
                                        <Home 
                                            path="/" 
                                            currentData={currentData} 
                                            state={state}
                                            enableSpecial={this.enableSpecial}
                                            finishIntro={this.finishIntro}
                                            introActive={introActive}
                                            activateMenu={this.activateMenu}
                                            deactivateMenu={this.deactivateMenu}
                                        />
                                        <Case 
                                            path="case/:slug"
                                            state={state}
                                            enableSpecial={this.enableSpecial}
                                        />
                                    </Router>
                                )}
                            </TransitionRouter>
                        )}
                    </Location>
                </AppContext.Provider>
            </div>
        );
    }
}
