import { throttle } from "lodash";
import { Touch } from "../utils/touch";
import { isMobile } from "./../utils/is-mobile";

// const maxSpeed = 20;

class Wheel3D {

    val = 0;
    touchVal = 0;
    speedTimeout = null;
    snapTimeout = null;
    stopped = false;

    lastTime = 0;
    toVal = 0;
    speed = 0;

    constructor(next, prev/*, selectNearest*/){
        this.next = throttle(next, 1000);
        this.prev = throttle(prev, 1000);
        // this.selectNearest = throttle(selectNearest, 1000);
        window.addEventListener('wheel', this.scroll);
        
        if (isMobile) {
            new Touch(this.touch, this.up);
        }
    }
    
    // scroll2 = e => {

    //     const time = window.performance.now();
    //     const diff = time - this.lastTime;

    //     if(diff < 30) return; // in ms
    //     // console.log(e.deltaY);
        
    //     this.lastTime =  time;
    //     let y = e.deltaY * 0.5;
    //     let val = Math.sign( e.deltaY ) * 5;
    //     let add = Math.abs(y) > Math.abs(val) ? val : y;
        
    //     this.speed = add;
    //     this.toVal += this.speed;

    //     clearTimeout(this.speedTimeout);

    //     this.speedTimeout = setTimeout( () => {
    //         this.speed = 0;
    //     }, 30)


    //     clearTimeout(this.snapTimeout);

    //     this.snapTimeout = setTimeout( () => {
    //         this.selectNearest();
    //     }, 300)

    //     // console.log(this.toVal, val);

    //     // if (!this.stopped) {
    //     //     if(this.toVal > 1){
    //     //         // code
    //     //         this.prev();
    //     //         this.val = 0;
    //     //         this.stopped = true;
    //     //         setTimeout(() =>{
    //     //             this.stopped = false
    //     //         },1500)
    //     //         // console.log('stopped');
    //     //     } 
    //     //     else if(this.val < -1){
    //     //         // code
    //     //         this.next();
    //     //         this.val = 0;
    //     //         this.stopped = true;
    //     //         setTimeout(() =>{
    //     //             this.stopped = false
    //     //         },1500)
    //     //         // console.log('stopped');
    //     //     }
    //     // }
    // };

    // scroll3 = e => {

    //     const time = window.performance.now();
    //     const diff = time - this.lastTime;

    //     if(diff < 30) return; // in ms
    //     console.log(e.deltaY);
        
    //     this.lastTime = time;
    //     let y = e.deltaY * 0.1;
    //     // let val = Math.sign( e.deltaY ) * 5;
    //     // let add = Math.abs(y) > Math.abs(val) ? val : y;
        
    //     this.toVal += y - this.speed;
    //     this.speed = y;

    //     clearTimeout(this.speedTimeout);

    //     this.speedTimeout = setTimeout( () => {
    //         this.speed = 0;
    //     }, 30)


    //     clearTimeout(this.snapTimeout);

    //     this.snapTimeout = setTimeout( () => {
    //         this.selectNearest();
    //     }, 200)
    // }

    scroll = e => {
        // const normalized = normalizeWheel(e);
        // console.log(normalized.pixelY, e.deltaY);
        this.val += e.deltaY;
        
        // console.log(this.val);
        
        // if (Math.abs(e.deltaY) < 15) {
        //     // console.log('Unstopped');
        //     this.stopped = false;
        //     this.val = 0;
        // }
        
        const trigger = (func) => {
            func('scroll');
            this.val = 0;
            this.stopped = true;

            setTimeout(() => {
                this.stopped = false
                this.val = 0;
                // console.log('Timeout finished');
            },1500)
        }

        if (!this.stopped) {
            if(this.val > 10){
                trigger(this.next);
            } 
            else if(this.val < -10){
                trigger(this.prev);
            }
        }

        // clearTimeout(this.timeout);

        // this.timeout = setTimeout( () => {
        //     this.val = 0;
        // }, 20)

        // console.log(e.deltaY)
    }

    touch = ({ x, y }) => {
        // console.log(x - y);
        this.touchVal = x + y;

        // if (Math.abs(x) > 50) {
        //     if (x < 0) this.prev();
        //     if (x > 0) this.next();
        //     return;
        // }

        // if (Math.abs(y) > 50) {
        //     if (y < 0) this.prev();
        //     if (y > 0) this.next();
        //     return;
        // }
    }

    up = ({ x, y }) => {
        // console.log('up', x - y);
        if (this.touchVal < -50) this.prev();
        else if (this.touchVal > 50) this.next();
        this.touchVal = 0;
    }
}

export default Wheel3D;