import * as THREE from 'three';
import { MeshLambertMaterial, PlaneGeometry } from 'three';
import { clamp, clean } from '../utils';

const SMOKE_COLOR = 0x666666;
const SMOKE_SIZE = 500;

class SmokeMesh extends THREE.Mesh {
	isSmoke = true;
	speed = 0.3;
	randRot = Math.random() * 2 - 1;

	constructor(texture, pos) {
        const geo = new PlaneGeometry(SMOKE_SIZE, SMOKE_SIZE);
		const material = new MeshLambertMaterial({
			color: SMOKE_COLOR,
			opacity: 1,
			map: texture,
			transparent: true,
			depthTest: false
		});

		super(geo, material);

		this.position.set(...pos);
		this.rotation.z = Math.random() * 360;
		this.startZ = this.position.z;
		this.startX = this.position.x;
	}

	fade(cameraZ, visibleDepth) {
		// const d = cameraZ - this.position.z;
		const d2 = (cameraZ - this.position.z) / visibleDepth;
		const fade = Math.abs(clamp(d2, 0, 1) - 1);
		let opacity = 1;

		if (fade > 0.5) {
			opacity = Math.abs(clean(fade, 0.5, 1) - 1);
		}

		if (fade < 0.3) {
			opacity = clean(fade, 0, 0.3);
		}

		this.material.opacity = 0.8 * opacity;
	}

	rotate(delta) {
		this.rotation.z += delta * this.speed * this.randRot;
	}

	update(delta, camz, depth, cycleD, faceVars) {
		this.move(camz, cycleD, faceVars);
		this.rotate(delta);
		this.fade(camz, depth);
	}

	move(cam, cycleD, { mult2, shift }) {
		const d = cam - this.startZ;
		const mult = Math.floor((cycleD - d) / cycleD);
		const cz = this.startZ - mult * cycleD;
		const nd = cam - cz;
		// const mult2 = 1.8;
		// const shift = 300;

		this.position.z = cz;
		this.position.x = this.startX - nd * mult2 + shift;
	}
}

export default SmokeMesh;
