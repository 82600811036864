import React, {Component} from 'react';
import classNames from "classnames";
import YouTube from 'react-youtube';
import Inview from '../Inview';
import { timeline } from '../../utils/timeline';

class Video extends Component {
    
    player = null;

    constructor(props) {
        super(props);
        
        this.ref = React.createRef();
        this.imageRef = React.createRef();
        this.state = {
            hidden: true,
            active: false
        };
    }

    componentDidMount() {

    }
    
    show = () => {
        if (!this.state.hidden) return;
        this.setState({
            hidden: false
        })
    };

    hide = () => {
        if (this.state.hidden) return;
        this.setState({
            hidden: true
        })
    };

    handleRead = (e) => {
        this.player = e.target;
    };

    handleStateChange = (e) => {
        // console.log(e.data);
        if (e.data === -1 || e.data === 0) {
            this.hide();
        } else {
            this.show();
        }
    }

    play = () => {
        if (!this.player) return;
        this.player.playVideo();
    };

    activate = () => {
        this.setState({ active: true });
        const tl = timeline({})
        .add({
            targets: this.imageRef.current,
            opacity: {
                value: [0, 1],
                easing: 'easeInOutSine',
            },
            scale: [1.1, 1],
            easing: 'easeOutCubic',
            duration: 3400
        }, 0)
    };

    render() {
        const { id, image } = this.props.data;
        const { hidden, active } = this.state;
        const opts = {
            height: '676',
            width: '1200',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
                showinfo: 0
            }
        };
        
        const backgroundImage = `url(${image})`
        const length = 50 * Math.PI * 2;

        return (
            <Inview
                el={this.ref.current}
                start={-1}
                end={1}
                cb={this.activate}
            >
                <div className={`video sides ${active ? 'is-active' : ''}`} ref={this.ref}>
                    <div className="video__wrap">
                        <YouTube
                            containerClassName={classNames({
                                'video__youtube': true,
                                'is-hidden': hidden,
                            })}
                            videoId={id}
                            opts={opts}
                            onStateChange={this.handleStateChange}
                            onReady={this.handleRead}
                        />
                        
                        <div 
                            className={classNames({
                                'video__over': true,
                                'is-hidden': !hidden,
                            })} 
                            onClick={this.play}
                        >
                            <div 
                                className={'video__image'} 
                                ref={this.imageRef}
                                style={{ backgroundImage }} 
                            >
                            </div>
                            <div 
                                className={classNames({
                                    'video__icon': true,
                                    'is-hidden': !hidden,
                                })}
                            >
                                <svg className="video__icon-play" xmlns="http://www.w3.org/2000/svg" width="102" height="102" viewBox="0 0 102 102">
                                    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                                        <path fill="#FFF" d="M43.487 38.678l21.802 13.14-21.802 13.14z"/>
                                    </g>
                                </svg>

                                <svg className="video__icon-circle" xmlns="http://www.w3.org/2000/svg" width="102" height="102" viewBox="0 0 102 102">
                                    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                                        <circle cx="50" cy="50" r="50" stroke="#FFF"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </Inview>
        );
    }
}

export default Video;
                