import React, {Component} from 'react';
import Inview from '../Inview';
import { timeline } from '../../utils/timeline';
import anime from 'animejs';
import wordWrap from '../../utils/word-wrap';

class Title extends Component {
    constructor(props) {
        super(props);
        
        this.ref = React.createRef();
    }

    activate = () => {
        const tl = timeline({})
        .add({
            targets: this.ref.current.querySelectorAll('span'),
            opacity: [0, 1],
            filter: {
                value: ['blur(10px)', 'blur(0px)'],
                duration: 1000,
            },
            translateZ: 0,
            easing: 'linear',
            duration: 1400,
            delay: anime.stagger(90)
        }, 200)
    };

    render() {
        const { text, size } = this.props.data;
        const config = {
            text: text,
            length: 2,
            indent: `<span>`,
            newline: '</span>',
        };

        return (
            <Inview
                el={this.ref.current}
                start={-1}
                end={1}
                cb={this.activate}
            >
                <div className={`title sides title--${size || 'normal'}`} ref={this.ref}>
                    <div className={`title__wrap`}>
                        <div className={`title__content`}>
                        { size ? <p className={`text-${size}`} dangerouslySetInnerHTML={{__html:  wordWrap(config) }} /> :
                            <h3 dangerouslySetInnerHTML={{__html: wordWrap(config) }}/>
                        }
                        </div>
                    </div>
                </div>
            </Inview>
        );
    }
}

export default Title;
                