import * as THREE from 'three';
import { data } from '../data';
import { clamp, clean } from '../utils';
import anime from 'animejs';

class ImageMesh extends THREE.Mesh {
	constructor({ x, y, z }, images, index, mult = 1, visibleDepth) {
		const { w, h } = data[index].data.slide;

        const imageGeo = new THREE.PlaneGeometry(w * mult, h * mult);
		const imageMaterial = new THREE.MeshBasicMaterial({
			alphaMap: images.mask,
			map: images.face,
			transparent: true,
			opacity: 0
        });

        super(imageGeo, imageMaterial);

		this.index = index;
		this.position.set(x, y, z);
		this.startZ = z;
		this.startX = x;
		this.opacity = {
			val: 0
		};
		this.visibleDepth = visibleDepth;

		this.fadeTimeline = anime.timeline({
            autoplay: false
        })
        .add({
            targets: this.opacity,
            val: [0, 1],
            easing: 'linear',
            duration: 2000,
        });
	}

	fade(cameraZ, vd) {
		const { z: meshZ } = this.position;
		// const startDepth = 180;
		// const d = cameraZ - startDepth - meshZ;
		const d2 = (cameraZ - meshZ) / vd;
		const fade = Math.abs(clamp(d2, 0, 1) - 1);
		

		let opacity = 1;
		
		if (fade > 0.74) {
			opacity = Math.abs(clean(fade, 0.74, 0.9) - 1);
		}

		if (fade < 0.7) {
			opacity = clean(fade, 0, 0.7);
		}

		this.fadeTimeline.seek(this.fadeTimeline.duration * opacity);
		// const fadeIn = 1 - d / (vd - startDepth);
		// const opacity = clamp(fadeIn, 0, 1);
		// const fadeOut = clamp(d2 * 4, 0, 1);
	
		if (this.index === 0) {
			// console.log(this.opacity.val);
		}

		this.material.opacity = this.opacity.val; //opacity * fadeOut;
	}

	move(cam, cycleD, { mult2, shift }) {
		const d = cam - this.startZ;
		const mult = Math.floor((cycleD - d) / cycleD);
		const cz = this.startZ - mult * cycleD;
		const nd = cam - cz;

        this.position.z = cz;
		this.position.x = this.startX - nd * mult2 + shift;
	}

	update(delta, camz, depth, cycleD, faceVars) {
		this.move(camz, cycleD, faceVars);
		this.fade(camz, depth);
	}
}

export default ImageMesh;
