import React, {Component} from 'react';
import Heading from "./../Heading";
import { Link } from "@reach/router";
import { af as AF } from '@gladeye/af';
import { timeline } from '../../utils/timeline';
import { AppContext } from "../../context/app-context";
import { isMobile } from "./../../utils/is-mobile";
import Parallax from '../Parallax';
import { clamp, round, clean } from '../../utils';
import anime from 'animejs';

class Next extends Component {
    timeline = null;
    exiting = false;

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.bgRef = React.createRef();
        this.contentRef = React.createRef();
        this.fixedRef = React.createRef();
        this.af = AF();
    }
    
    componentDidMount() {
        if (!isMobile) {
            this.imageTimeline = anime.timeline({
                autoplay: false,
                complete: (anim) => {
                }
            })
            .add({
                targets: this.bgRef.current,
                scale: {
                    value: [1.15, 1.1], 
                    easing: 'easeInQuad'
                },
                opacity: [0, .75],
                translateY: [40, 15],
                translateZ: 0,
                easing: 'easeInQuad',
                duration: 2000,
            })
            .add({
                targets: this.contentRef.current,
                opacity: {
                    value: [0, 1],
                    duration: 1500,
                    easing: 'easeInOutCubic'
                },
                translateY: [200, 80],
                translateZ: 0,
                easing: 'easeInQuad',
                duration: 2000,
            }, 0);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { state } = this.props;
        const { transitionSpecial } = this.context;
        const { current, previous } = this.context.location;

        if (current === 'case' && previous === 'case' && transitionSpecial && state === 'exiting') {
            this.animateOut();
        }
    }
    
    animateOut = () => {
        this.exiting = true;

        if (this.timeline) {
            this.timeline.destroy();
            return;
        }

        const { transitionDuration } = this.context;
        
        if (isMobile) {
            
        } else {
            this.timeline = timeline({})
            .add({
                targets: this.bgRef.current,
                scale: 1,
                translateY: 0,
                translateZ: 0,
                opacity: {
                    value: 1,
                    easing: 'easeInOutQuart',
                },
                easing: 'easeInOutQuart',
                duration: transitionDuration
            })
            .add({
                targets: this.contentRef.current,
                opacity: 1,
                translateY: 0,
                translateZ: 0,
                easing: 'easeInOutQuart',
                duration: transitionDuration
            }, 0);
        }
    };
    
    write = (smoothVal) => {
        if (this.exiting) return;
        if (!isMobile) {
            const progress = clean(smoothVal, -0.8, 0);
            this.imageTimeline.seek(this.imageTimeline.duration * progress);
        }
    };

    render() {
        const { enableSpecial, headingPos, state } = this.props;
        const { slug } = this.props.next;
        const { title, sub, image } = this.props.next.data;

        return (
            <Parallax 
                className="next"
                start={-0.8}
                end={0}
                cb={this.write}
            >
                <Link 
                    to={`/case/${slug}`}
                    onClick={enableSpecial}
                    className="next__link" 
                ></Link>
                <div 
                    className="next__fixed"
                    ref={this.fixedRef}
                >
                    <div 
                        className="next__content"
                        ref={this.contentRef}
                    >
                        <Heading 
                            title={title} 
                            sub={sub} 
                            sup={'Next'}
                            type={'next'}
                            headingPos={headingPos}
                            state={state}
                            enableSpecial={enableSpecial}
                        />
                    </div>

                    <div
                        className="next__bg" 
                    >
                        <span
                            ref={this.bgRef}
                            style={{ backgroundImage: `url(${image})` }}>
                        </span>
                    </div>
                </div>
            </Parallax>
        );
    }
}

export default Next;
                