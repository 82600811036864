import React, { Component } from "react";
import { debounce } from "lodash";

class HeadingPos extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.handleResize = debounce(this.handleResize, 250);
    }

    componentDidMount() {
        window.addEventListener('load', this.handleResize);
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleResize);
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        const { setHeadingPos } = this.props;
        const rect = this.ref.current.getBoundingClientRect();
        setHeadingPos(rect);
    };

    render() {
        return <div className={"heading-pos"} ref={this.ref} />;
    }
}

export default HeadingPos;
