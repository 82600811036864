class opViewProgress {
    r;
    wh;

    constructor(el, threshold = 0) {
        this.el = el;
        this.tr = threshold;
        this.tick();
    }

    tick() {
        this.r = this.el.getBoundingClientRect();
        this.wh = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    get value() {
        return (1 - (this.r.top + this.r.height * (1 - this.tr)) / (this.wh + this.r.height * ( 1 - this.tr*2))) * 2 - 1;
    }
}

export { opViewProgress };