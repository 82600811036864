export class Touch {
    _onDown = () => {};
    _onMove = () => {};
    _onUp = () => {};
    
    swiped = false;

    mouse = {
        down: false,
        moved: false,
        type: '',
        xStart: 0,
        yStart: 0,
        xDist: 0,
        yDist: 0,
        tap: false,
    };

    constructor(onMove, onUp)  {
        this._onMove = onMove;
        this._onUp = onUp;

        if ('ontouchstart' in document.documentElement) {
            window.addEventListener('touchstart', this._handleDown);
            window.addEventListener('touchmove', this._handleMove);
            window.addEventListener('touchend', this._handleUp);
        } else {
            window.addEventListener('mousedown', this._handleDown);
            window.addEventListener('mousemove', this._handleMove);
            window.addEventListener('mouseup', this._handleUp);
        }
    }

    _handleDown = (e) => {
        let { mouse: m, /*_onDown: _onD*/ } = this;
        if (m.down) return;
        m.type = getType(e);
        m.down = true;
        m.moved = false;
        m.tap = false;
        m.xStart = getX(e);
        m.yStart = getY(e);
        // _onD();
    };

    _handleMove = (e) => {
        let { mouse: m, _onMove: _onM } = this;
        if (getType(e) !== m.type) return;

        m.xDist = (getX(e) - m.xStart); //* this.mult;
        m.yDist = getY(e) - m.yStart;

        // if (!m.moved && Math.abs(m.yDist) > Math.abs(m.xDist)) { 
        //     // If moved Y more than X (i.e scrolling)
        //     m.down = false;
        // }

        // if (m.down) e.preventDefault()
        if (!m.down) return;

        m.moved = true;

        // if ((Math.abs(m.xDist) > 50 || Math.abs(m.yDist) > 50) && !this.swiped) {
        //     this.swiped = true;
            _onM({x: m.xDist, y: m.yDist});
        // }
    };

    _handleUp = (e) => {
        let { mouse: m, _onUp: _onU } = this;
        if (!m.down) return;
        if (getType(e) !== m.type) return;
        m.down = false;
        m.xDist = getX(e) - m.xStart;
        m.yDist = getY(e) - m.yStart;
        this.swiped = false;

        if (Math.abs(m.xDist) < 5) {
            // Tap
            m.tap = true;
            return;
        } else e.preventDefault();

        _onU({x: m.xDist, y: m.yDist});
    };
}

export function getX(e) {
    if (e.changedTouches) return e.changedTouches[0].clientX;
    else return e.clientX;
}

export function getY(e) {
    if (e.changedTouches) return e.changedTouches[0].clientY;
    else return e.clientY;
}

export function getType(e){
    let type;
    
    switch(e.type){
        case 'mousedown':
        case 'mouseup':
        case 'mousemove':
            type = 'mouse';
            break
        case 'touchstart':
        case 'touchend':
        case 'touchmove':
            type = 'touch';
            break
        case 'pointerdown':
        case 'pointerup':
        case 'pointermove':
            type = 'pointer';
            break
    }

    return type;
}