import React, { Component } from 'react';

class WindowHeight extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        const { innerHeight: h, innerWidth: w } = window;
        this.ref.current.style.width = `${w}px`;
        this.ref.current.style.height = `${h}px`;
    };

    render() {
        const { ...rest } = this.props;
        return (
            <div className={`window-height ${this.props.className}`} {...rest} ref={this.ref}>
                { this.props.children }
            </div>
        );
    }
}

export default WindowHeight;
