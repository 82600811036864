import React, { Component } from "react";
import { Link } from "@reach/router";
import { timeline } from '../utils/timeline';
import { AppContext } from "../context/app-context";

class Sub extends Component {
    
    timeline = null;
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.lineRef = React.createRef();
        this.contentRef = React.createRef();

        this.state = {
            hidden: true
        }
    }
    
    componentDidMount() {
        this.check();
    }

    componentDidUpdate(prevProps) {
        this.check();
    }

    check = () => {
        const { hidden, delay } = this.props;

        if (hidden) {
            this.hide(delay);
        } else {
            this.show(delay);
        }
    };

    hide = (delay) => {
        const { hidden } = this.state;
        if (hidden) return;

        this.setState({ hidden: true })
        if (this.timeline) this.timeline.destroy();
        
        this.lineRef.current.style.transformOrigin = 'right center';
        this.timeline = timeline({})
            .add({
                targets: this.lineRef.current,
                scaleX: [1, 0],
                duration: 400,
                easing: 'easeOutQuad',
                delay: delay
            })
            .add({
                targets: this.contentRef.current,
                opacity: 0,
                duration: 500,
                easing: 'easeOutQuad',
                delay: delay
            }, 0);
    };

    show = (delay) => {
        const { hidden } = this.state;
        if (!hidden) return;

        this.setState({ hidden: false })
        if (this.timeline) this.timeline.destroy();

        this.lineRef.current.style.transformOrigin = 'left center';
        this.timeline = timeline({})
            .add({
                targets: this.lineRef.current,
                scaleX: [0, 1],
                translateZ: 0,
                duration: 1600,
                easing: 'easeOutQuint',
                delay: delay
            })
            .add({
                targets: this.contentRef.current,
                translateX: [40, 0],
                translateZ: 0,
                opacity: 1,
                duration: 1600,
                easing: 'easeOutQuint',
                delay: delay
            }, 0);
    };

    render() {
        const { url, text } = this.props;

        return (
            <div className={`sub`}>
                <div className="sub__line" ref={this.lineRef}></div>
                <div className="sub__content" ref={this.contentRef}>
                    <div className={`sub__text`}>{ text }</div>
                    { url ? 
                        <div className="sub__more">Read more</div>
                    : '' }
                </div>
            </div>
        );
    }
}

export default Sub;
