import wrap from 'word-wrap';

export default function wordWrap(config) {
    const { text, length, indent, newline } = config;
    
    return wrap(text, {
        width: length,
        trim: true,
        escape: function(string){
            return String(indent) + string.trim() + String(newline);
        }
    });
}
