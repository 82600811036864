import { refugee } from "./refugee";
import { trafficking } from "./trafficking";
import { privacy } from "./privacy";
import { slavery } from "./slavery";

export const data = [
    {
        slug: 'trafficking',
        data: trafficking
    },
    {
        slug: 'refugee',
        data: refugee
    },
    {
        slug: 'slavery',
        data: slavery
    },
    {
        slug: 'privacy',
        noactive: true,
        data: privacy
    },
]