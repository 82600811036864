export const privacy = {
    title: 'The<br/>Surveillance<br/>Economy',
    sub: 'Privacy',
    image: require(`../../images/hero-4.jpg`),
    slide: {
        image: require(`../../images/slide-image-privacy.jpg`),
        mask: require(`../../images/slide-image-privacy-mask.jpg`),
        w: 456,
        h: 760
    },
    blocks: [
        {
            block: 'TextBlock',
            data: {
                size: 23,
                pos: 'right',
                text: `
                    <p>Here is an introduction to how a lack of identity impacts human trafficking in the country the story is based. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at nisl nisi.</p> 
                    <p>Nulla ex erat, consectetur at consectetur a, aliquam a felis. Sed non magna sit amet neque lobortis rutrum ac sit amet urna.</p>
                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'left',
                caption: `Here is a caption about the story behind the image from Getty. A child bride cowers at her husband's feet.`,
                image: require(`../../images/image.jpg`)
            }
        },
        {
            block: 'FocusText',
            data: {
                text: `More than a billion  people today are without an identity - 600 million of them are children.`
            }
        },
        {
            block: 'Image',
            data: {
                type: 'right',
                caption: `Here is a caption about the story behind the image from Getty. A human-trafficking victim sits at a police station after being rescued from a village in Karnal around 100 kms from New Delhi`,
                image: require(`../../images/image-2.jpg`)
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'left',
                text: `
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at nisl nisi. Nulla ex erat, consectetur at consectetur a, aliquam a felis. Sed non magna sit amet neque lobortis rutrum ac sit amet urna. Phasellus at dignissim sem. Sed a imperdiet ex.</p> 
                    <p>Praesent sed augue mi. Aenean sit amet arcu arcu. Curabitur elit ipsum, elementum sit amet lacus in, imperdiet rhoncus nisi. Nunc condimentum porttitor dui, in eleifend dui. Ut eget elementum ligula, vitae hendrerit purus.  Nulla aliquet viverra elit sit amet fringilla. Duis porttitor odio sit amet eros mattis tristique.</p>
                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'full',
                caption: `A human-trafficking victim (R) is hugged by her sister after being rescued from a village in Karnal around 100 kms from New Delhi`,
                image: require(`../../images/image-3.jpg`)
            }
        },
        {
            block: 'Title',
            data: {
                text: `Meena  Haseena’s story`,
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 23,
                pos: 'right',
                text: `
                    <p>Meena Haseena was eight years old when she was kidnapped by her uncle and sold to a brothel in northern India. For twelve years she was a sexual slave; raped, beaten, drugged and abused.</p> 
                    <p>She gave birth to two children in this time – both were taken from her and abused by the brothel’s owners. Finally, after so much suffering, Meena found a way to escape her captors and return to her village.</p>
                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'right',
                caption: `Here is a caption about the story behind the image of Meena`,
                image: require(`../../images/image-4.jpg`)
            }
        },
        {
            block: 'FocusText',
            data: {
                text: `For twelve years  she was… raped, beaten, drugged and abused.`
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'right',
                text: `
                    <p>Meena’s own story emphasizes the importance of having a proof of identity. After her escape Meena tried everything to get her children back. But they were born in the brothel and never officially registered. And without a birth certificate or legal identity of her own, Meena had no options. Again she was lucky – a local NGO helped her to rescue her children.</p> 
                    <p>In most cases, however, those children would have remained trapped – by their captors, and by their inability to prove who they are. Without a proof of identity, these children are practically “invisible” to those who may help and for that matter are at great risk.</p>
                `
            }
        },
    ]
}