import React, { Component } from "react";
import { Link } from "@reach/router";
import { timeline } from '../utils/timeline';
import { AppContext } from "../context/app-context";

class Header extends Component {
    burgerTimeline = null;

    static contextType = AppContext;

    constructor(props) {
        super(props);
        
        this.line1Ref = React.createRef();
        this.line2Ref = React.createRef();
    }

    transformClose = () => {
        if (this.burgerTimeline) this.burgerTimeline.destroy();

        this.burgerTimeline = timeline({})
        .add({
            targets: this.line1Ref.current,
            rotateZ: 45,
            translateY: 4,
            translateZ: 0,
            easing: 'easeOutQuart',
            duration: 700
        })
        .add({
            targets: this.line2Ref.current,
            rotateZ: -45,
            translateY: -4,
            translateZ: 0,
            easing: 'easeOutQuart',
            duration: 700
        }, 0)
    };

    transformBurger = () => {
        if (this.burgerTimeline) this.burgerTimeline.destroy();

        this.burgerTimeline = timeline({})
        .add({
            targets: this.line1Ref.current,
            rotateZ: 0,
            translateY: 0,
            translateZ: 0,
            easing: 'easeOutQuart',
            duration: 700
        })
        .add({
            targets: this.line2Ref.current,
            rotateZ: 0,
            translateY: 0,
            translateZ: 0,
            easing: 'easeOutQuart',
            duration: 700
        }, 0)
    };

    toggle = () => {
        const { menuActive } = this.context;
        const { activateMenu, deactivateMenu } = this.props;

        if (menuActive) {
            deactivateMenu();
            this.transformBurger();
        } else {
            activateMenu();
            this.transformClose();
        }
    };

    render() {
        const { menuActive } = this.context;
        const { activateMenu, deactivateMenu } = this.props;

        return (
            <div className={`header sides text-13`}>
                <div className={'header__wrap'}>
                    <div className={`header__title ${menuActive ? 'is-hidden' : ''}`}>World Identity Network</div>
                    {/*<div className={`header__burger ${menuActive ? 'is-close' : ''}`} onClick={this.toggle}>
                        <span ref={this.line1Ref}></span>
                        <span ref={this.line2Ref}></span>
                    </div>*/}


                    <Link to={'/about'} className={`header__right`}>
                        <span>About</span>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Header;
