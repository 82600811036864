import React, {Component} from 'react';
import Heading from "./../Heading";
import { timeline } from '../../utils/timeline';
import { AppContext } from "../../context/app-context";
import { isMobile } from "./../../utils/is-mobile";
import Parallax from '../Parallax';
import { clamp, round, clean } from '../../utils';
import anime from 'animejs';

class Hero extends Component {
    timeline = null;

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.bgRef = React.createRef();
        this.bgWrapRef = React.createRef();
        this.contentRef = React.createRef();

        this.state = {
            visible: false
        }
    }

    componentDidMount() {
        // this.check();

        if (!isMobile) {
            this.opacityTimeline = anime.timeline({
                autoplay: false,
                complete: (anim) => {
                }
            })
            .add({
                targets: this.bgWrapRef.current,
                opacity: [1, 0],
                scale: [1, 1.1],
                translateZ: 0,
                translateZ: 0,
                easing: 'linear',
                duration: 2000,
            })
            .add({
                targets: this.contentRef.current,
                opacity: {
                    value: [1, 0],
                    duration: 1500,
                    easing: 'easeInOutCubic',
                    delay: 500,
                },
                translateY: [0, -200],
                translateZ: 0,
                easing: 'easeInQuad',
                duration: 2000,
            }, 0);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // this.check();
    }

    check = () => {
        const { current, previous } = this.context.location;
        const { isCurrent, state } = this.props;
        const { transitionSpecial } = this.context;
        
        if (previous !== 'case') {
            this.animateIn();
        } else {
            if (transitionSpecial) {
                if (isMobile) {
                    this.animateIn(600);
                } else {
                    if (state === 'entered') {
                        if (this.state.visible) return;
                        this.setState({ visible: true });
                    }
                }
            } else {
                this.animateIn();
            }
        }
    };
    
    animateIn = (delay = 0) => {
        if (this.state.visible) return;
        if (this.timeline) this.timeline.destroy();
        
        const { current, previous } = this.context.location;
        const { transitionDuration } = this.context;

        this.setState({ visible: true });
        
        // if (isMobile) {
        //     this.timeline = timeline({}).
        //         add({
        //             targets: this.bgRef.current,
        //             opacity: [0, 1],
        //             easing: 'easeOutQuart',
        //             duration: transitionDuration * 0.35,
        //         });
        // } else {
            if (previous === 'home' && current === 'case') {
                this.timeline = timeline({}).
                add({
                    targets: this.bgRef.current,
                    opacity: [0, 1],
                    scale: [1.1, 1],
                    translateZ: 0,
                    easing: 'easeOutQuart',
                    duration: 5000,
                    delay: transitionDuration * 0.5
                });
            } else {
                this.timeline = timeline({}).
                add({
                    targets: this.bgRef.current,
                    opacity: [0, 1],
                    scale: [1.1, 1],
                    translateZ: 0,
                    easing: 'easeOutQuart',
                    duration: 2500,
                    delay: delay
                });
            }
        // }
    };
    
    write = (smoothVal) => {
        this.check();
        
        if (!isMobile) {
            const progress = clean(smoothVal, 0, 0.5);
            this.opacityTimeline.seek(this.opacityTimeline.duration * progress);
        }
    };

    render() {
        const { visible } = this.state;
        const { title, sub, image, special } = this.props.hero;
        const { state } = this.props;

        const TagName = special ? 'div' : Parallax;
        const props = {
            className: `hero ${special ? 'hero--special' : ''} ${visible ? 'is-hero-visible' : ''}`
        }

        if (!special) {
            props.start = 0;
            props.end = 0.5;
            props.cb = this.write;
        }

        return (
            <TagName {...props}>   
                <div className="hero__fixed">
                    <div className="hero__bg" ref={this.bgWrapRef}>
                        <span ref={this.bgRef} style={{ backgroundImage: `url(${image})` }}></span>
                    </div>
                    
                    <div className="hero__content" ref={this.contentRef}>
                        <Heading title={title} sub={sub} special={special} state={state} />
                    </div>
                </div>
            </TagName>
        );
    }
}

export default Hero;