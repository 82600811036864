import React, { Component } from "react";
import { Link } from "@reach/router";
import { af as AF } from '@gladeye/af';
import { isIE } from "./../utils/detectIE";
import { timeline } from '../utils/timeline';
import Inview from './Inview';
import anime from 'animejs';
import { round } from '../utils';
import { AppContext } from "../context/app-context";
import Sub from "./Sub";
import { isMobile } from "./../utils/is-mobile";

class Heading extends Component {
    rect;
    timelines = [];
    xTranslate = 50;

    state = {
        visible: false
    };

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.titleRef = React.createRef();
        this.topLine = React.createRef();
        this.bottomLine = React.createRef();
        this.topContent = React.createRef();
        this.bottomContent = React.createRef();
        this.af = AF();
    }
    
    componentDidMount() {
        const { innerWidth: width } = window;
        this.xTranslate = Math.max(round(width * 0.026, 10), 25);
    }

    componentDidUpdate(prevProps) {
        const { current, previous } = this.context.location;
        const { transitionSpecial, menuActive } = this.context;
        const { isCurrent, state, type } = this.props;
        this.rect = this.ref.current.getBoundingClientRect();
        
        // Don't animate-in Next heading
        if (type === 'next') return;

        if (current === 'home') {
            if (isCurrent) {
                this.animateIn(600);
            } else {
                this.animateOut();
            }
        } else if (current === 'case' && transitionSpecial) {
            if (state === 'entered'){
                if (isMobile) {
                    this.animateIn();
                } else {
                    if (previous !== 'home' && previous !== 'case') {
                        this.animateIn();
                    } else {
                        if (this.state.visible) return;
                        this.setState({ visible: true });
                    }
                }
            }

            if (state === 'exiting'){
                if (isMobile) {
                    this.animateOut();
                }
            }
        } else {
            if (!previous) {
                this.animateIn();
            } else {
                this.animateIn(600);
            }
        }
    }

    componentWillUnmount(nextProps, nextState) {
        this.clean();
    }
    
    activate = () => {
        const { type } = this.props;

        if (type === 'next') {
            if (this.state.visible) return;
            this.animateIn();
        }
    };

    renderTitle() {
        let { title } = this.props;

        let newTitle = '<span class="row">';
        let tag = false;
        for (let i = 0; i < title.length; i++) {
            let a = title[i];
            let next = title[i + 1];
            let prev = title[i - 1];
            
            if (a === '<' || a === '&') {
                tag = true;
                if (a === '<' && prev) newTitle += '</span>';
            }

            if (a !== ' ' && !tag) {
                newTitle += `<span class="letter">${a}</span>`;
            } else {
                newTitle += a;
            }

            if (a === '>' || a === ';') {
                tag = false;
                if (a === '>' && next) newTitle += '<span class="row">';
            }
        }
        newTitle += '</span>'

        return (
            <div className="heading__title"> 
                <h2 ref={this.titleRef} dangerouslySetInnerHTML={{__html: newTitle }}/>
            </div>
        );
    }

    clean() {
        this.timelines.forEach((tl) => {
            tl.destroy();
        });
    }
        
    animateIn = (delay) => {
        if (this.state.visible) return;

        const rows = [].slice.call(this.titleRef.current.querySelectorAll('.row'));
        const startDelay = delay || 0;
        
        this.setState({ visible: true });
        this.clean();

        rows.forEach((row, index) => {
            const tl = timeline({})
                .add({
                    targets: row.querySelectorAll('.letter'),
                    opacity: {
                        value: [0, 1],
                        duration: 1600,
                        easing: 'linear'
                    },
                    translateX: {
                        value: [this.xTranslate, 0],
                        duration: 1600,
                        easing: 'easeOutQuint'
                    },
                    translateZ: 0,
                    delay: anime.stagger(50, {start: startDelay + index * 150})
                });
            this.timelines.push(tl);
        });
    };

    animateOut = (delay = 0) => {
        if (!this.state.visible) return;

        this.setState({ visible: false });
        this.clean();
        
        const tl = timeline({})
            .add({
                targets: this.titleRef.current.querySelectorAll('.letter'),
                opacity: {
                    value: 0,
                    duration: 250,
                    easing: 'easeOutSine',
                    delay: delay
                },
            });
        this.timelines.push(tl);
    };

    moveTitle = () => {
        //
    };

    render() {
        const { sub, sup, state, isCurrent, url, special, enableSpecial } = this.props;
        const { visible } = this.state;
        const { headingPos, menuActive } = this.context;
        const { current, previous } = this.context.location;

        const TagName = url ? Link : 'div';
        const props = url ? { to: url, onClick: enableSpecial } : {};
        
        let subHidden = true;
        let subDelay = 0;
        
        if (current === 'home') {
            if (isCurrent) {
                if (menuActive) {
                    subHidden = true;
                } else {
                    subHidden = false;
                    subDelay = 1000;
                }
            }
            if (state === "exiting") {
                subHidden = true;
            }
        } else if (current) {
            if (state === "entered") {
                if (visible) {
                    subHidden = false;
                }

                if (!previous) {
                    subDelay = sup ? 0 : 1000;
                }
            }

            if (state === "exiting") {
                subHidden = true;
            }
        }

        return (
            <Inview
                el={this.ref.current}
                start={-0.5}
                end={1}
                cb={this.activate}
                >
                <TagName 
                    className={`heading ${url ? 'heading--link' : ''} ${special ? 'heading--special' : ''} ${visible ? 'is-heading-visible' : ''}`} 
                    ref={this.ref}
                    // style={style}
                    {...props} 
                >
                    <div className="heading__wrap">
                        { this.renderTitle() }

                        <div className={`heading__sub heading__sub--${sup ? 'top' : 'bottom'}`}>
                            <Sub text={sup || sub} url={url} hidden={subHidden} delay={subDelay}/>
                        </div> 
                    </div>
                </TagName>
            </Inview>
        );
    }
}

export default Heading;
