import React, {Component} from 'react';
import { Link } from "@reach/router";

class Back extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {

        return (
            <div className={`back sides`}>
                <div className={`back__wrap`}>
                    <Link to="/" className="back__link text-13">
                        Back to index
                    </Link>
                </div>
            </div>
        );
    }
}

export default Back;