import React, {Component} from 'react';

const items = [
    {
        role: 'Story',
        auths: [
            {
                title: 'World Identity Network',
                link: '#'
            }
        ]
    },
    {
        role: 'Design & Development',
        auths: [ 
            {
                title: 'Gladeye',
                link: 'https://gladeye.com'
            }
        ]
    },
]

class Credits extends Component {
    
    renderItem = (item, index) => {
        return (
            <div className="credits__item text-18" key={index}>
                <div className="credits__role medium">{item.role}</div>
                { item.auths.map((auth, index) => {
                    if (auth.link) {
                        return (<a href={auth.link} key={index} className="credits__title arrow-link">{auth.title}</a>);
                    } else {
                        return (<div key={index} className="credits__title">{auth.title}</div>);
                    }
                })}
            </div>
        );
    };

    render() {
        return (
            <div className={`credits sides`}>
                <div className={`credits__content`}>
                    <div className="credits__head">
                        <h3>Credits</h3>
                    </div>
                    <div className="credits__list">
                        { items.map(this.renderItem) }
                    </div>
                </div>
            </div>
        );
    }
}

export default Credits;
                