import React, { Component } from 'react';
import {
	Scene,
	Clock,
	Color,
	WebGLRenderer,
	AmbientLight,
	PerspectiveCamera,
	PlaneGeometry,
	WebGLRenderTarget,
	OrthographicCamera,
	Mesh,
	MeshBasicMaterial,
	
	CustomBlending,
	AddEquation,
	SrcAlphaFactor,
	OneMinusSrcAlphaFactor,
	ShaderMaterial
	// OneMinusSrcAlphaFactor
} from 'three';

import loader from './Loader';
import { af as AF } from '@gladeye/af';
import Wheel3D from './wheel3D';
import Mouse3D from './mouse3D';
import SmokeMesh from './SmokeMesh';
import ImageMesh from './ImageMesh';
import { data } from '../data';
import { rand, round } from '../utils';
import { timeline } from '../utils/timeline';
import classNames from "classnames";
import { isMobile } from "./../utils/is-mobile";
import { AppContext } from "../context/app-context";

const CLEAR_COLOR = 0x101010;
const AMBIENT_LIGHT_COLOR = 0xffffff;
let smokeCount = null; //Defined below //data.length * 70;
let slideDepth = 200; // Z depth of each slide
let clearSpace = 110;

class Smoke extends Component {
	smokeMesh = [];
	faceMesh = [];
	textures = {};
	snapAnimating = false;
	xMult = 6; // X pan multipler
	yMult = -2; // X pan multipler

	faceVars = {
		mult2: 2,
		shift: 300
	};

	smokeVars = {
		mult2: 1.8,
		shift: 300
	};

	smoke = {
		opacity: 0
	};

	faces = {
		opacity: 0
	};

	camera = {
		x: {
			val: 0,
			to: 0
		}, 
		y: {
			val: 0,
			to: 0
		}, 
		z: {
			val: 0,
			to: 0
		}
	};

	moveTimeline = null;
	fadeTimelines = [];
	faceTimeline = null;

    static contextType = AppContext;

	constructor(props) {
		super(props);
		this.canvas = React.createRef();
		this.af = AF();
		this.wheel3D = new Wheel3D(props.goNext, props.goPrev /*, this.selectNearest*/);
		this.mouse3D = Mouse3D.instance();

		this.state = {
			paused: true,
			smokeVisible: false,
			facesVisible: false,
			sceneVisible: false, 
		}
	}

	componentDidMount() {
		const { innerWidth: w } = window;

		smokeCount = Math.min(Math.round(data.length * w * 0.0364), 250);//70
		if (isMobile && w < 768) {
			smokeCount = smokeCount*0.5;
		}

		this.imageLoader = this.loadImages();

		this.imageLoader.then(() => {
			this.init();
			this.af.addWrite(this.write);
			this.checkState();

			window.addEventListener('resize', this.resize);
			this.resize();
		});
	}

	componentDidUpdate(prevProps) {
		this.imageLoader.then(() => this.checkState(prevProps))
    }
	
	// Check current component state
    checkState = (prevProps = null) => {
		const { currentSlide, introActive } = this.props;
		const { current, previous } = this.context.location;
		const { menuActive } = this.context;

		if (prevProps !== null) {
			if (currentSlide !== prevProps.currentSlide) {
				this.moveTo(currentSlide);
			}
		}
		
		const checkMenu = () => {
			if (menuActive) {
				this.fadeOutFaces(600);
				this.fadeOutSmoke(0, 0.8);
				timeline({}).add({
				  	targets: this,
					// xMult: 100,
				  	easing: 'easeInOutQuad',
				  	duration: 1500,
				});
			} else {
				timeline({}).add({
				  	targets: this,
					// xMult: 6,
				  	easing: 'easeOutCubic',
				  	duration: 1400,
				});
				this.fadeInFaces(1000, 400);
				this.fadeInSmoke();
			}

		}

		if (current === 'home') {
			if (previous === null) {
				if (!introActive) {
					checkMenu();
				} else {
					this.fadeInSmoke(1000);
				}
			} else {
				this.fadeIn();
				checkMenu();
			}
        }

        if (previous === 'home') {
			this.fadeOut();
        }
    };

	loadImages() {
		return new Promise((resolve) => {
			const smoke = [ require(`../../images/Smoke-Element.png`) ];
			const faces = [];
			const faceMasks = [];

			data.forEach((d) => {
				const { image, mask } = d.data.slide;
				faces.push(image);
				faceMasks.push(mask);
			});

			const toLoad = [ ...smoke, ...faces, ...faceMasks ];

			loader.texture(toLoad).then((images) => {
				this.textures.smoke = images.slice(0, smoke.length);
				this.textures.faces = images.slice(smoke.length, smoke.length + faces.length);
				this.textures.faceMasks = images.slice(
					smoke.length + faces.length,
					smoke.length + faces.length + faceMasks.length
				);

				resolve();
			});
		});
    }

	init = () => {
		const { innerWidth: width, innerHeight: height } = window;

		this.clock = new Clock();
		this.renderer = new WebGLRenderer({
			canvas: this.canvas.current,
			alpha: false,
			antialias: false,
			depth: false
		});

		this.renderer.setClearColor(CLEAR_COLOR, 0);

		this.initW = width;
		this.initH = height;

		this.renderer.setSize(width, height);

		this.initScenes(width, height);
		this.initSmoke();
		this.initFaces();
	};

	initScenes(width, height) {
		this.scene = new Scene();
		this.smokeScene = new Scene();
		this.faceScene = new Scene();
		this.smokeTarget = new WebGLRenderTarget(width * 0.3, height * 0.3);
		this.faceTarget = new WebGLRenderTarget(width, height);

		this.smokePlane = new Mesh(
			new PlaneGeometry(width, height),
			new MeshBasicMaterial({
				map: this.smokeTarget.texture,
				transparent: true,
				depthTest: false,
				opacity: 0
			})
		);
		this.facePlane = new Mesh(
			new PlaneGeometry(width, height),
			new MeshBasicMaterial({
				map: this.faceTarget.texture,
				transparent: true,
				depthTest: false,
				// blending: CustomBlending,
				// blendEquation: AddEquation, //default
				// blendSrc: SrcAlphaFactor, //default
				// blendDst: SrcAlphaFactor, //default
			})
		);

		this.facePlane.material.opacity = 0;

		const visibleDepth = slideDepth * 2;
		const ambientLight = new AmbientLight(AMBIENT_LIGHT_COLOR);

		this.smokePlane.position.z = -1;

		this.orthographicCam = new OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 1000);
		this.orthographicCam.position.z = 1;

		this.scene.add(this.smokePlane, this.facePlane, this.orthographicCam);
		this.scene.background = new Color(CLEAR_COLOR);

		this.faceScene.add(ambientLight);
		this.smokeScene.add(ambientLight.clone());

		this.camera1 = new PerspectiveCamera(95, width / height, 1, visibleDepth);
		this.camera1.position.z = 0;
		this.camera2 = this.camera1.clone();

		this.smokeScene.add(this.camera1);
		this.faceScene.add(this.camera2);
	}

	initSmoke() {
		const { innerWidth: width, innerHeight: height } = window;
		let smokeTexture = this.textures.smoke[0];

		for (let i = 0; i < smokeCount; i++) {
			const zStart = -slideDepth * i;
			const zEnd = -slideDepth * i - slideDepth;

			const pos = [ 
				rand(-width*0.15, width*0.4), 
				rand(-height*0.5, height*0.2),
				rand(zStart, zEnd),
			];
			const smoke = new SmokeMesh(smokeTexture, pos);
			this.smokeMesh.push(smoke);
			this.smokeScene.add(smoke);
		}
	}

	initFaces() {
		for (let i = 0; i < data.length; i++) {
			const zStart = -slideDepth * i;
			const vec3 = {
				x: 0,
				y: 0,
				z: zStart - clearSpace + 1
			};
			const mult = slideDepth / 500 * (clearSpace / slideDepth);
			const { visibleDepth } = this;
			const images = {
				mask: this.textures.faceMasks[i],
				face: this.textures.faces[i]
			};
			const face = new ImageMesh(vec3, images, i, mult, visibleDepth);
			this.faceMesh.push(face);
			this.faceScene.add(face);
		}
	}

	resize = () => {
		const { innerHeight: h, innerWidth: w } = window;
		this.camera1.aspect = w / h;
		this.camera1.updateProjectionMatrix();
		this.camera2.aspect = w / h;
		this.camera2.updateProjectionMatrix();
		this.renderer.setSize(w, h);
		this.smokeTarget.setSize(w / 3, h / 3);
		this.faceTarget.setSize(w, h);

		if (h > w) {
			this.faceVars = {
				mult2: 1.1,
				shift: 160
			};

			this.smokeVars = {
				mult2: 1,
				shift: 160
			};
		} else {
			this.faceVars = {
				mult2: 2,
				shift: 300
			};
			this.smokeVars = {
				mult2: 1.8,
				shift: 300
			};
		}
	};

	moveCam() {
		this.camera1.position.x = this.camera2.position.x = this.camera.x.val;
		this.camera1.position.y = this.camera2.position.y = this.camera.y.val;
		this.camera1.position.z = this.camera2.position.z = this.camera.z.val;
	}
	
	// selectNearest = () => {
	// 	const { goIndex } = this.props;
	// 	let newIndex = Math.round(this.z.to / slideDepth);
		
	// 	goIndex(newIndex);
	// 	this.moveTo(newIndex);
	// };

	fade(to, ease, delay = 0) {
        const { transitionDuration } = this.context;
		this.snapAnimating = true;

		const tick = () => {
			this.facePlane.material.opacity = this.faces.opacity;
			this.smokePlane.material.opacity = this.smoke.opacity;
		}

		this.fadeTimelines.forEach((tl) => {
            tl.destroy();
        });
        this.fadeTimelines = [];

		const tl = timeline({
			tick: tick,
			onComplete: () => { this.snapAnimating = false },
		  	duration: transitionDuration*0.5,
		}).add({
		  	targets: this.camera.z,
		  	val: to.z,
		  	easing: ease,
			delay: delay,
		}, 0)
		.add({
		  	targets: this.camera.x,
		  	val: to.x,
		  	easing: ease,
			delay: delay,
		}, 0)
		.add({
		  	targets: [this.smoke, this.faces],
		  	opacity: to.opacity,
		  	easing: ease,
			delay: delay,
		}, 0);

		this.fadeTimelines.push(tl);
	}

	fadeIn() {
        const { transitionDuration } = this.context;
		if (this.state.sceneVisible) return;
		this.setState({ sceneVisible: true, paused: false });
		
		const to = {
			opacity: 1,
			z: this.camera.z.to,
			x: this.camera.x.to
		};
		
		const delay = transitionDuration*0.2;

		this.fade(to, 'easeOutQuad', delay);
	}

	fadeOut() {
		if (!this.state.sceneVisible) return;
		this.setState({ sceneVisible: false });

		const to = {
			opacity: 0,
			z: this.camera.z.to,
			x: this.camera.x.to
		};

		this.fade(to, 'easeInOutSine');
	}

	moveTo(index) {
		this.snapAnimating = true;
		
		if (this.moveTimeline) this.moveTimeline.destroy();
		
		this.camera.z.to = slideDepth * index;

		this.moveTimeline = timeline({
			onComplete: () => { 
				this.snapAnimating = false;
			}
		}).add({
		  	targets: this.camera.z,
		  	val: this.camera.z.to,
		  	easing: 'cubicBezier(.35,0,.1,1)',
		  	// easing: changeType === 'scroll' ? 'cubicBezier(.35,0,.1,1)' : 'easeOutCubic',
		  	duration: 1500,
		  	round: 10
		});
	}
	
	fadeInSmoke(delay = 0) {
		if (this.state.smokeVisible) return;
		this.setState({ smokeVisible: true, paused: false, sceneVisible: true });
		
		if (this.smokeTimeline) this.smokeTimeline.destroy();

		const tick = () => {
			this.smokePlane.material.opacity = this.smoke.opacity;
		}

		const tl = timeline({
			tick: tick
		}).add({
		  	targets: this.smoke,
		  	opacity: 1,
		  	easing: 'easeOutSine',
		  	duration: 1500,
		  	delay: delay
		});
		
		this.smokeTimeline = tl;
		this.fadeTimelines.push(tl);
	}

	fadeOutSmoke(delay = 0, opacity = 0.2) {
		if (!this.state.smokeVisible) return;
		this.setState({ smokeVisible: false });
		
		if (this.smokeTimeline) this.smokeTimeline.destroy();

		const tick = () => {
			this.smokePlane.material.opacity = this.smoke.opacity;
		}

		const tl = timeline({
			tick: tick
		}).add({
		  	targets: this.smoke,
		  	opacity: opacity,
		  	easing: 'easeOutSine',
		  	duration: 1500,
		  	delay: delay
		});
		
		this.smokeTimeline = tl;
		this.fadeTimelines.push(tl);
	}

	fadeInFaces(duration = 1500, delay = 0) {
		if (this.state.facesVisible) return;
		this.setState({ facesVisible: true, sceneVisible: true });
		
		if (this.faceTimeline) this.faceTimeline.destroy();

		const tick = () => {
			this.facePlane.material.opacity = this.faces.opacity;
		}

		const tl = timeline({
			tick: tick
		}).add({
		  	targets: this.faces,
		  	opacity: 1,
		  	easing: 'easeOutSine',
		  	duration: duration,
		  	delay: delay
		});

		this.faceTimeline = tl;
		this.fadeTimelines.push(tl);
	}

	fadeOutFaces(duration = 1000) {
		if (!this.state.facesVisible) return;
		this.setState({ facesVisible: false });
		
		if (this.faceTimeline) this.faceTimeline.destroy();

		const tick = () => {
			this.facePlane.material.opacity = this.faces.opacity;
		}
		
		const z = this.camera.z.to + 10;

		const tl = timeline({
			tick: tick
		}).add({
		  	targets: this.faces,
		  	opacity: 0,
			z: this.camera.z.to,
		  	easing: 'easeOutSine',
		  	duration: duration,
		});

		this.faceTimeline = tl;
		this.fadeTimelines.push(tl);
	}

	write = () => {
		if (this.state.paused) return;
		const scope = this;
		const { introActive } = this.props;
		const { menuActive } = this.context;

		this.delta = this.clock.getDelta();

		this.camera.x.val = this.xMult * this.mouse3D.smoothMouse.x;
		this.camera.y.val = this.yMult * this.mouse3D.smoothMouse.y;
		this.moveCam();
		
		
		// if (Math.abs(this.z.val - this.z.to) > 1 && !this.snapAnimating) {
		// 	const dist = this.z.to - this.z.val;
		// 	this.z.val = round(this.z.val + dist * 0.02, 1000);
		// }
		// this.move();

		this.smokeMesh.forEach(function(mesh) {
			mesh.update(scope.delta, scope.camera1.position.z, slideDepth * 2, slideDepth * data.length, scope.smokeVars);
		});
		
		if (!introActive) {
			this.faceMesh.forEach(function(mesh) {
				mesh.update(scope.delta, scope.camera1.position.z, slideDepth * 2, slideDepth * data.length, scope.faceVars);
			});
		}

		// this.renderer.setRenderTarget(this.smokeTarget);
		this.renderer.render(this.smokeScene, this.camera1, this.smokeTarget);

		// this.renderer.setRenderTarget(this.faceTarget);
		this.renderer.render(this.faceScene, this.camera2, this.faceTarget);

		// this.renderer.setRenderTarget(null);
		this.renderer.render(this.scene, this.orthographicCam);
	};

	// move = () => {
	// 	const { currentSlide } = this.props;
	// 	this.z.to = slideDepth * currentSlide - this.wheel3D.touchVal * 0.2;
		
	// 	if (Math.abs(this.z.val - this.z.to) > 1 && !this.snapAnimating) {
	// 		const dist = this.z.to - this.z.val;
	// 		this.z.val = round(this.z.val + dist * 0.02, 1000);
	// 	}
	// };

	render() {
		const { paused } = this.state;
		return (
			<div className={`smoke ${paused ? 'is-paused' : ''}`}>
				<canvas ref={this.canvas} />
			</div>
		);
	}
}

export default Smoke;
