import React, {Component, Fragment} from 'react';
import { af as AF } from '@gladeye/af';
import { opViewProgress } from '../utils/opViewProgress';

class Inview extends Component {
    active = false;
    initialised = false;

    constructor(props) {
        super(props);
        this.af = AF();
    }
    
    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.af.removeRead(this.read);
    }

    componentDidUpdate(prevProps, prevState) {
        this.init();
    }

    init() {
        const { el } = this.props;
        if (el) {
            if (this.initialised) return;
            this.initialised = true;
            this.pr = new opViewProgress(el, 0);
            this.af.addRead(this.read);
        }
    }

    read = () => {
        const { start, end, cb } = this.props;
        this.pr.tick();
        
        if (this.pr.value > start && this.pr.value < end) {
            if (this.active) return;
            this.active = true;
            this.af.removeRead(this.read);
            cb();
        }
    };
    
    render() {
        return (
            <Fragment>
                { this.props.children }
            </Fragment>
        );
    }
}

export default Inview;
                