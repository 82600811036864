import React, {Component, Fragment} from 'react';
import ScrollDown from "./ScrollDown";
import Heading from "./Heading";
import WindowHeight from "./WindowHeight";
import { data } from "../data";
import Header from "./Header";
import Intro from "./Intro";
import { af as AF } from '@gladeye/af';
import { AppContext } from "../context/app-context";
import Mouse3D from './mouse3D';
import { round } from '../utils';
import { isMobile } from "./../utils/is-mobile";
import { timeline } from '../utils/timeline';

class Home extends Component {
    x = 0; y = 0;
    
    exited = false;
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.af = AF();
        this.mouse3D = Mouse3D.instance();

        this.state = {
            current: null
        }
    }
    
    componentDidMount() {
        if (!isMobile) {
            this.af.addWrite(this.write);
        }
    }

    componentWillUnmount() {
        if (!isMobile) {
            this.af.removeWrite(this.write);
        }
    }

    componentDidUpdate(prevProps) {
        const { current, previous } = this.context.location;
        const { transitionSpecial } = this.context;
        const { state, currentData } = this.props;
        
        if (this.state.current !== currentData) {
            this.af.onNextWrite(() => {
                this.setState({
                    current: currentData
                });
            });
        }
        
        if (current === 'case' && transitionSpecial && state === 'exiting') {
            this.animateOut();
        }
    }

    animateOut = () => {
        if (this.exited) return;
        const { transitionDuration } = this.context;

        this.exited = true;
        this.af.removeWrite(this.write);
        
        const anim = {
            x: this.x,
            y: this.y
        };

        const tick = () => {
            if (this.listRef.current) {
                this.listRef.current.style.transform = `translate3d(${round(anim.x, 100)}px, ${round(anim.y, 100)}px, 0)`;
            }
        }

        const tl = timeline({
            tick: tick,
        })
        .add({
            targets: anim,
            x: 0,
            y: 0,
            duration: transitionDuration,
            easing: 'easeInOutQuart'
        });
    };
    
    write = () => {
        this.x = round(-45 * this.mouse3D.smoothMouse.x, 100);
        this.y = round(-20 * this.mouse3D.smoothMouse.y, 100);

        if (this.listRef.current) {
            this.listRef.current.style.transform = `translate3d(${this.x}px, ${this.y}px, 0)`;
        }
    };

    renderItem = (item, index) => {
        const { state, enableSpecial } = this.props;
        const { current } = this.state;
        let { title, sub } = item.data;
        const isCurrent = current === index;

        let url = `/case/${item.slug}`;

        if (item.noactive) {
            url = false;
            sub = "Coming soon";
        }

        return <div 
            className={`home__item ${isCurrent ? 'is-current' : ''}`} 
            key={index}
            >
                <div className="home__item-content">
                    <Heading 
                        title={title} 
                        sub={sub} 
                        state={state} 
                        isCurrent={isCurrent}
                        enableSpecial={enableSpecial}
                        url={url}
                    />
                </div>
            </div>
    };

    render() {
        const { currentData: current, state, finishIntro, introActive, activateMenu, deactivateMenu } = this.props;

        return (
            <WindowHeight className={`home`}>
                { introActive ? <Intro finishIntro={finishIntro} /> : (
                    <Fragment>

                        <Header 
                            activateMenu={activateMenu}
                            deactivateMenu={deactivateMenu}
                        />
                        
                        <div className="home__list" ref={this.listRef}>
                            { data.map(this.renderItem) }
                        </div>

                        <ScrollDown state={state}/>                    

                        <div className="home__bottom text-13 sides">
                            <div className="home__bottom-wrap">
                                <a href="https://win.systems/join-us/" className={'home__bottom-link'}>
                                    <img src={require(`../../images/arrow-45.svg`)} alt="" />Get involved
                                </a>
                                <a href="https://win.systems/donate/" className={'home__bottom-link'}>
                                    <img src={require(`../../images/arrow-45.svg`)} alt="" />Donate
                                </a>
                            </div>
                        </div>

                    </Fragment>
                )}
            </WindowHeight>
        );
    }
}

export default Home;
                