import { createContext } from "react";

export const AppContext = createContext({
    transitionDuration: 0,
    transitionSpecial: false,
    menuActive: false,
    location: {
        current: '',
        previous: ''
    },
    headingPos: ''
});
