import React, {Component, Fragment} from 'react';
import { af as AF } from '@gladeye/af';
import { opViewProgress } from '../utils/opViewProgress';
import { round } from '../utils';

class Parallax extends Component {
    active = true;
    initialised = false;
    smoothVal = 0;

    constructor(props) {
        super(props);
        this.af = AF();
        this.ref = React.createRef();
    }
    
    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.af.removeRead(this.read);
        this.af.removeWrite(this.write);
    }

    componentDidUpdate(prevProps, prevState) {
        this.init();
    }

    init() {
        const { start, end, cb, speed = 0.5 } = this.props;
        if (this.ref.current) {
            if (this.initialised) return;
            this.pr = new opViewProgress(this.ref.current, 0);
            this.smoothVal = start;
            this.af.addRead(this.read);
            this.af.addWrite(this.write);
        }
    }

    read = () => {
        const { start, end, cb } = this.props;
        this.pr.tick();
    };

    write = () => {
        const { start, end, cb, speed = 0.5 } = this.props;
        
        const progress = round(this.pr.value, 1000)
            // console.log(this.pr.value)
        if (progress >= start && progress <= end) {
            if (Math.abs(this.smoothVal - progress) < 0.001) {
                if (!this.initialised) {
                    cb(this.smoothVal);
                }

                return;
            }
            this.smoothVal += ( progress - this.smoothVal ) * speed;
            
            cb(this.smoothVal);
            this.active = true;
        } else {
            if (!this.active) return;
            this.smoothVal = progress <= start ? start : end;
            cb(this.smoothVal);
            this.active = false;
        }

        this.initialised = true;
    }
    
    render() {
        const { start, end, cb, ...rest } = this.props;
        return (
            <div {...rest} ref={this.ref}>
                { this.props.children }
            </div>
        );
    }
}

export default Parallax;
                