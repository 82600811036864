import React, {Component, Fragment} from 'react';
import TextBlock from "./TextBlock";
import Image from "./Image";
import Title from "./Title";
import FocusText from "./FocusText";
import Video from "./Video";

const components = {
    TextBlock: TextBlock,
    Image: Image,
    Title: Title,
    FocusText: FocusText,
    Video: Video
};

class Blocks extends Component {
    renderBlock = (item, index) => {
        const TagName = components[item.block];

        return (
            <TagName key={index} data={item.data} />
        );
    };

    render() {
        const { blocks } = this.props;

        return (
            <Fragment>
                { blocks.map(this.renderBlock) }
            </Fragment>
        );
    }
}

export default Blocks;
                