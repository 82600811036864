export const slavery = {
    title: 'Slaves of<br/>the&nbsp;Present<br/>Day',
    sub: 'Modern Slavery',
    image: require(`../../images/slavery/hero-2.jpg`),
    slide: {
        image: require(`../../images/slide-image-slavery.jpg`),
        mask: require(`../../images/slide-image-slavery-mask.jpg`),
        w: 750,
        h: 819
    },
    blocks: [
        {
            block: 'TextBlock',
            data: {
                size: 23,
                pos: 'right',
                text: `
                    <p>Many people believe that slavery is a thing of the past, a vicious practice that was abolished years ago. But that couldn’t be further from the <span>truth</span>, there are more slaves in the world today than there has ever been.</p> 
                    <p>Victims of slavery are all over the world, but because they are trapped behind closed doors they remain an <span>invisible</span> part of society.</p>
                    <p>The victims are also hidden by their lack of <span>identity</span>. Slaves can’t be counted or freed if they have no identification documents to show they exist. Slave owners use this to their advantage, they take the slave’s ID documents and destroy them so they can never be found.</p>
                `
            }
        },
        {
            block: 'FocusText',
            data: {
                text: `If everyone had an indestructible proof of their identity, then it could help to fight slavery.`
            }
        },
        {
            block: 'Title',
            data: {
                text: `Katya’s Story`,
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'column',
                text: `
                    <p>20-year-old Katya had a tough upbringing in local Ukrainian orphanages after her father was murdered and her mother passed away when she was a child. But when she reached her 18th birthday, it only got worse.</p>

                    <p>Katya was kicked out of the orphanage with her unborn child, and with <span>nowhere to go</span>, she was forced to live on the streets.</p>

                    <p>After the birth of her baby girl Masha, Katya became trapped in a begging operation. Captors had taken Masha <span>hostage</span>, and being homeless had left Katya vulnerable. She was in no position to resist.</p>

                    <p>Katya was forced to beg for hours everyday, but she never saw the money that kind strangers would give her as she was forced to give it back to her captors. And if she didn’t come home with any money, then she was forbidden from seeing Masha.</p>

                    <p>After some time Katya was able to escape, but she had to leave <span>her daughter</span> behind.</p>

                    <p>In her freedom she looked for help from local charities to help her save her child. With their help, Masha was found and reunited with Katya.</p>

                    <p>Now in a family support centre, Katya is struggling to get <span>identification</span> documents which she needs to get social support for her and Masha, so they can start a new life together.</p>
                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'right',
                image: require(`../../images/slavery/image-child.jpg`)
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'left',
                text: `
                    <p class="medium">Child Slaves</p>
                    <p>But it’s not just adults that are forced into slavery, children are more <span>vulnerable</span> which makes them a easier target for modern slavery. They are often sold by their families, or lured in through scams and false pretenses. And once they get involved in the slave trade, most children never escape.</p>
                    <p>A common form of child slavery is the Kamlari/Kamaiya issue, where families sell their daughters as child slaves to pay off their debts. Over the last decade 12,000 Kamlari’s have been freed as the government tries to abolish the practice. But now the system is working in <span>secret</span>, and it is estimated that 500-1,000 Kamlari’s remain in captivity.</p>
                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'full',
                image: require(`../../images/slavery/banner-image.jpg`)
            }
        },
        {
            block: 'Title',
            data: {
                text: `Meena’s Story`,
            }
        },
        {
            block: 'TextBlock',
            data: {
                size: 18,
                pos: 'column',
                text: `
                    <p>Meena Haseena was 8 years old when she was <span>kidnapped</span> by her uncle.</p>

                    <p>Meena, an Indian Muslim, was taken from her village near the Nepalese border and sold to a sexual slavery institution.</p> 

                    <p>As a young girl, she was kept in a rural house until she was mature enough to begin attracting customers. When men began to request her she would kick and scream in protest.</p> 

                    <p>The brothel owners beat her and threatened to kill her to get Meena to stop fighting but she resisted. Eventually they turned to <span>drugging</span> her to get her to comply.</p> 

                    <p>She was forced to work in the brothel for 12 years and she estimated that she was beaten on five days of every week she was enslaved.</p> 

                    <p>During this time she gave birth to two children, both of which were <span>taken</span> from her and abused by her captors.</p>

                    <p>At age 20, she was able to escape, but she had to leave her children behind.</p>

                    <p>With the help of an NGO, Meena was able to rescue her daughter Naina, who had also been forced into prostitution, as well as her other child.</p>

                    <p>Today she shares her story to encourage awareness and create a movement against modern slavery. She has contributed to a book and has a short film based on her experiences.</p>

                `
            }
        },
        {
            block: 'Image',
            data: {
                type: 'left',
                image: require(`../../images/slavery/image-two-girls.jpg`)
            }
        },
    ]
}