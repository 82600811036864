import {
    TextureLoader
} from 'three';

class Loader{

    constructor(){
        TextureLoader.prototype.crossOrigin = '';
        this.textureLoader = new TextureLoader();
    }

    texture(images){
        return new Promise( ( resolve ) => {
            const imgs = [].concat(images);
            const textures = new Array(imgs.length);
            let loaded = 0;

            imgs.forEach( (img, i) => {
                this.textureLoader.load(img,  (texture) => {
                    textures[i] = texture;
                    loaded++;
                    if(loaded === imgs.length) resolve( imgs.length === 1 ? texture : textures);
                });
            })
        })
    }

}


export default new Loader();